import { Box, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import ToggleButton from './ToggleButton'

const ToggleButtonWithValidation = (props) => {
  const { name, control, rules, label, asVal, items } = props
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="subtitle2" sx={{ mb: '3px' }}>
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: { onChange, value, onBlur },
          fieldState: { invalid, isTouched, isDirty, error },
        }) => <ToggleButton onChange={onChange} value={value} items={items} />}
      />
    </Box>
  )
}

export default ToggleButtonWithValidation
