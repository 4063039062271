import { configureStore, combineReducers } from '@reduxjs/toolkit'

import loaderSlice, { loaderSliceActions } from '../store/slices/loaderSlice'
import userSlice, { userSliceActions } from '../store/slices/userSlice'
import basedataSlice, { basedataSliceActions } from './slices/basedataSlice'
import knowledgeSlice, { knowledgeSliceActions } from './slices/knowledgeSlice'
import agentSlice, { agentSliceActions } from './slices/agentSlice'

// export actions to access and handle future changes easily
export const userActions = userSliceActions
export const loaderActions = loaderSliceActions
export const basedataActions = basedataSliceActions
export const knowledgeActions = knowledgeSliceActions
export const agentActions = agentSliceActions

const rootReducer = combineReducers({
  loader: loaderSlice,
  user: userSlice,
  basedata: basedataSlice,
  knowledge: knowledgeSlice,
  agent: agentSlice,
})

export default configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
})
