import { useEffect, useState } from 'react'

const ProjectImage = (props) => {
  const { src, alt, containerProps, image, ...imgProps } = props
  const [imgSrc, setImgSrc] = useState(
    src || `${process.env.PUBLIC_URL}/placeholder.jpg`
  )

  useEffect(() => {
    setImgSrc(src || `${process.env.PUBLIC_URL}/placeholder.jpg`)
  }, [src])

  return (
    <img
      {...imgProps}
      src={imgSrc}
      alt={alt}
      onError={() => {
        setImgSrc(`${process.env.PUBLIC_URL}/placeholder.jpg`)
      }}
    />
  )
}

ProjectImage.defaultProps = {
  style: {
    objectFit: 'cover',
    width: '100%',
    heigth: '100%',
  },
}

export default ProjectImage
