import { Close, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  Box,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Select as MuiSelect,
} from '@mui/material'
import { useState } from 'react'

const Select = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const {
    value,
    onChange,
    items,
    onBlur,
    disabled,
    noResultsText,
    ...textFieldProps
  } = props

  const handleChange = (item) => {
    handleClose()
    onChange(item)
  }

  return (
    <>
      <TextField
        value={value || ''}
        placeholder={textFieldProps.label || ''}
        onClick={disabled ? undefined : handleOpen}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              {!open && (
                <KeyboardArrowDown color={disabled ? 'disabled' : undefined} />
              )}
              {open && <KeyboardArrowUp />}
              {!props.required && !open && value && (
                <IconButton
                  aria-label="clear"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleChange(null)
                  }}
                  edge="end">
                  <Close />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        disabled={disabled}
        {...textFieldProps}
      />
      <Menu
        data-no-dnd="true"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onBlur={onBlur}
        sx={{ width: '100%' }}>
        {!items || items.length === 0 ? (
          <MenuItem disabled>{noResultsText}</MenuItem>
        ) : (
          items.map((item) => (
            <MenuItem
              key={typeof item === 'object' ? item.id + item.title : item}
              value={typeof item === 'object' ? item.id : item}
              onClick={() => handleChange(item)}
              sx={{ whiteSpace: 'normal' }}>
              <Box>
                <Typography variant="caption" sx={{ display: 'block' }}>
                  {typeof item === 'object' ? item.title : item}
                </Typography>
                {typeof item === 'object' && item.description && (
                  <Typography variant="body2">{item.description}</Typography>
                )}
              </Box>
            </MenuItem>
          ))
        )}
      </Menu>
      {/* <MuiSelect
        fullWidth
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        label={textFieldProps.label}>
        {!items || items.length === 0 ? (
          <MenuItem disabled>No results</MenuItem>
        ) : (
          items.map((item) => (
            <MenuItem
              key={item.id + item.title}
              value={item}
              sx={{ whiteSpace: 'normal' }}>
              <Box>
                <Typography variant="caption" sx={{ display: 'block' }}>
                  {item.title}
                </Typography>
                {item.description && (
                  <Typography variant="body2">{item.description}</Typography>
                )}
              </Box>
            </MenuItem>
          ))
        )}
      </MuiSelect> */}
    </>
  )
}

Select.defaultProps = {
  margin: 'none',
  fullWidth: true,
  error: false,
  helperText: '',
  onBlur: () => {},
  required: false,
  noResultsText: 'No results',
}

export default Select
