import { TextField } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import myDayjs from '../../utils/dayjs'
import { selectedLang } from '../../utils/auth'
import { useState } from 'react'

// TODO: handle locale

const Datepicker = (props) => {
  const {
    name,
    rules,
    helperText,
    onChange,
    onBlur,
    error,
    value,
    disabled,
    inputFormat,
    placeholder,
    ...textFieldProps
  } = props

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={selectedLang()}>
      <DatePicker
        label={props.label}
        inputFormat={inputFormat}
        value={value}
        onChange={(payload) => {
          onChange(myDayjs(payload).format(inputFormat))
        }}
        name={name}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            {...{ inputProps: { ...params.inputProps, placeholder } }}
            error={Boolean(error)}
            helperText={error ? helperText[error.type] : ''}
            required={rules.required}
            value={value}
            {...textFieldProps}
            onBlur={onBlur}
            fullWidth
            disabled={disabled}
          />
        )}
      />
    </LocalizationProvider>
  )
}

Datepicker.defaultProps = {
  margin: 'none',
  variant: 'outlined',
  fullWidth: true,
  helperText: '',
  rules: {},
  inputFormat: 'YYYY-MM-DD',
}

export default Datepicker
