import { TextField } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import myDayjs from '../../utils/dayjs'
import { selectedLang } from '../../utils/auth'

const Timepicker = (props) => {
  const {
    name,
    rules,
    helperText,
    onChange,
    onBlur,
    error,
    value,
    disabled,
    placeholder,
    ...textFieldProps
  } = props

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale={selectedLang()}>
      <TimePicker
        label={props.label}
        inputFormat="HH:mm"
        value={value}
        onChange={(payload) => {
          onChange(myDayjs(payload).format('HH:mm'))
        }}
        name={name}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            {...{ inputProps: { ...params.inputProps, placeholder } }}
            error={Boolean(error)}
            helperText={error ? helperText[error.type] : ''}
            required={rules.required}
            value={value}
            {...textFieldProps}
            onBlur={onBlur}
            fullWidth
            disabled={disabled}
          />
        )}
      />
    </LocalizationProvider>
  )
}

Timepicker.defaultProps = {
  margin: 'none',
  variant: 'outlined',
  fullWidth: true,
  helperText: '',
  rules: {},
}

export default Timepicker
