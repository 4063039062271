import axios from '../axios'

const base = '/agent'

export const getAgents = (filter = {}) => {
  return axios.get(`${base}/`, {
    params: filter,
  })
}
export const createAgent = (payload) => {
  return axios.post(
    `${base}/`,
    payload
    // , {
    //   mimeType: 'multipart/form-data',
    // }
  )
}
export const getAgentDetail = (id) => {
  return axios.get(`${base}/${id}/`)
}
export const editAgent = (id, payload) => {
  return axios.put(
    `${base}/${id}/`,
    payload
    // , {
    //   mimeType: 'multipart/form-data',
    // }
  )
}
export const deleteAgent = (id) => {
  return axios.delete(`${base}/${id}/`)
}
export const publishAgent = (id, payload) => {
  return axios.put(`${base}/${id}/publish/`, payload)
}
export const unPublishAgent = (id, payload) => {
  return axios.put(`${base}/${id}/unpublish/`, payload)
}

export const getAgentEntityTypes = (id) => {
  return axios.get(`${base}/${id}/entity-types/`)
}
export const createAgentEntityType = (id, payload) => {
  return axios.post(`${base}/${id}/entity-types/`, payload)
}
export const getEntityTypeDetail = (id) => {
  return axios.get(`${base}/entity-type/${id}/`)
}
export const updateEntityType = (id, payload) => {
  return axios.put(`${base}/entity-type/${id}/`, payload)
}
export const deleteEntityType = (id) => {
  return axios.delete(`${base}/entity-type/${id}/`)
}

export const getAgentFlows = (id) => {
  return axios.get(`${base}/${id}/flows/`)
}
export const createAgentFlow = (id, payload) => {
  return axios.post(`${base}/${id}/flows/`, payload)
}
export const getFlowDetail = (id) => {
  return axios.get(`${base}/flow/${id}/`)
}
export const updateFlow = (id, payload) => {
  return axios.put(`${base}/flow/${id}/`, payload)
}
export const deleteFlow = (id) => {
  return axios.delete(`${base}/flow/${id}/`)
}

export const getFlowPages = (id) => {
  return axios.get(`${base}/flow/${id}/pages/`)
}
export const createFlowPage = (id, payload) => {
  return axios.post(`${base}/flow/${id}/pages/`, payload)
}
export const getPageDetail = (id) => {
  return axios.get(`${base}/page/${id}/`)
}
export const updatePage = (id, payload) => {
  return axios.put(`${base}/page/${id}/`, payload)
}
export const deletePage = (id) => {
  return axios.delete(`${base}/page/${id}/`)
}

export const createPageRoute = (id, payload) => {
  return axios.post(`${base}/page/${id}/routes/`, payload)
}
export const getPageRoutes = (id) => {
  return axios.get(`${base}/page/${id}/routes/`)
}
export const getRouteDetail = (id) => {
  return axios.get(`${base}/route/${id}/`)
}
export const updateRoute = (id, payload) => {
  return axios.put(`${base}/route/${id}/`, payload)
}
export const deleteRoute = (id) => {
  return axios.delete(`${base}/route/${id}/`)
}

export const getAgentIntents = (id) => {
  return axios.get(`${base}/${id}/intents/`)
}
export const createAgentIntent = (id, payload) => {
  return axios.post(`${base}/${id}/intents/`, payload)
}
export const getIntentDetail = (id) => {
  return axios.get(`${base}/intent/${id}/`)
}
export const updateIntent = (id, payload) => {
  return axios.put(`${base}/intent/${id}/`, payload)
}
export const deleteIntent = (id) => {
  return axios.delete(`${base}/intent/${id}/`)
}

export const getAgentWebhooks = (id) => {
  return axios.get(`${base}/${id}/webhooks/`)
}
export const createAgentWebhook = (id, payload) => {
  return axios.post(`${base}/${id}/webhooks/`, payload)
}
export const getWebhookDetail = (id) => {
  return axios.get(`${base}/webhook/${id}/`)
}
export const updateWebhook = (id, payload) => {
  return axios.put(`${base}/webhook/${id}/`, payload)
}
export const deleteWebhook = (id) => {
  return axios.delete(`${base}/webhook/${id}/`)
}

export const getAgentRoles = () => {
  return axios.get(`${base}/agent-roles/`)
}
