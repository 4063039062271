import {
  FormControlLabel,
  FormGroup,
  // Button,
  // ButtonGroup,
  ToggleButton,
  ToggleButtonGroup,
  Switch as MuiSwitch,
  FormControl,
} from '@mui/material'

const Switch = (props) => {
  const { value, onChange, label } = props

  return (
    <>
      <FormControl margin="normal">
        <FormControlLabel
          labelPlacement="start"
          control={
            <MuiSwitch
              checked={value}
              color="primary"
              onChange={(e) => onChange(e.target.checked)}
            />
          }
          sx={{
            ml: 0,
            // mr: 2,
            '& .MuiSwitch-root': {
              ml: 1,
            },
          }}
          label={label}
        />
      </FormControl>
    </>
  )
}

export default Switch
