import { Box, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import Switch from './Switch'

const SwitchWithValidation = (props) => {
  const { name, control, rules, label } = props
  return (
    <Box sx={{ width: '100%' }}>
      {/* <Typography variant="subtitle2" sx={{ mb: '3px' }}>
        {label}
      </Typography> */}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: { onChange, value, onBlur },
          fieldState: { invalid, isTouched, isDirty, error },
        }) => <Switch onChange={onChange} value={value} label={label} />}
      />
    </Box>
  )
}

export default SwitchWithValidation
