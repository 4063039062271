import { Controller } from 'react-hook-form'
// import Select from '@mui/material/Select'
// import { MenuItem } from '@mui/material'
import Select from './Select'

const SelectWithValidation = (props) => {
  const {
    name,
    control,
    rules,
    helperText,
    asVal = 'title',
    noResultsText,
    // label,
    // placeholder,
    ...selectProps
  } = props
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: { onChange, value, onBlur },
          fieldState: { invalid, isTouched, isDirty, error },
        }) => (
          <Select
            onChange={(objectVal) => onChange(objectVal)}
            margin="normal"
            noResultsText={noResultsText}
            // error={(invalid || error) && (isTouched || isDirty)}
            error={Boolean(error)}
            helperText={error ? helperText[error.type] : ''}
            value={value ? (asVal ? value[asVal] : value) : null}
            onBlur={onBlur}
            required={rules?.required}
            {...selectProps}
          />
          // <Select
          //   margin="dense"
          //   IconComponent={'path'}
          //   error={Boolean(error)}
          //   // helperText={error ? helperText[error.type] : ''}
          //   value={value ? (asVal ? value[asVal] : value) : ''}
          //   onBlur={onBlur}
          //   required={rules.required}
          //   onChange={(objectVal) => onChange(objectVal)}
          //   fullWidth
          //   labelId={name + '-label'}
          //   label={label}
          //   placeholder={placeholder}>
          //   <MenuItem value={10}>Ten</MenuItem>
          //   <MenuItem value={20}>Twenty</MenuItem>
          //   <MenuItem value={30}>Thirty</MenuItem>
          // </Select>
        )}
      />
    </>
  )
}

// using example
// <SelectWithValidation
//     name="who"
//     control={control}
//     rules={{ required: true }}
//     helperText={{ required }}
//     label={t("demoReq.whoAreYou")}
//     items={roles}
// />

export default SelectWithValidation
