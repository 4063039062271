import { SendOutlined } from '@mui/icons-material'
import { Box, Container, IconButton, TextField } from '@mui/material'
import { useEffect, useRef } from 'react'
import { useTranslate } from '../../locales/useTranslateHook'
import Message from './Message'
import MessageField from './MessageField'
import { isMessageFromUser } from '../../../utils/contentTypes'

const Chat = (props) => {
  const {
    messages,
    text,
    setText,
    typing,
    onSend,
    placeholder,
    disabled,
    Append,
    Starter,
  } = props
  const translate = useTranslate()
  const containerRef = useRef(null)

  useEffect(() => {
    if (!containerRef || !containerRef.current || messages.length === 0) return
    setTimeout(() => {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }, 100)
  }, [messages])

  return (
    <>
      <Container
        fixed
        ref={containerRef}
        sx={{
          py: '0 !important',
          flexGrow: 1,
          overflowY: 'overlay',
          // '&:hover::-webkit-scrollbar-thumb': {
          //   backgroundColor: '#bababa',
          // },
          '&::-webkit-scrollbar': {
            width: '7px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#ffffff',
          },
          '&::-webkit-scrollbar-thumb': {
            // backgroundColor: 'transparent',
            backgroundColor: '#bababa',
            borderRadius: '10px',
            position: 'absolute',
          },
        }}>
        {Starter && <Starter />}
        {messages.map((item, index) => (
          <Message
            message={item.text}
            key={item.id + index}
            side={isMessageFromUser(item.side) ? 'right' : 'left'}
            first={
              (messages[index - 1] && messages[index - 1].side !== item.side) ||
              !messages[index - 1]
            }
            last={
              (messages[index + 1] && messages[index + 1].side !== item.side) ||
              !messages[index + 1]
            }
            Append={Append && Append(item, index)}
          />
        ))}
        {typing && <Message typing side="left"></Message>}
      </Container>
      <MessageField
        text={text}
        setText={setText}
        onSend={onSend}
        placeholder={placeholder}
        disabled={disabled}
        options={messages[messages.length - 1]?.options}
      />
    </>
  )
}

export default Chat
