import { Box, CircularProgress } from '@mui/material'

const Loader = (props) => {
  return (
    <Box
      sx={
        props.local
          ? {
              ...props.containerStyle,
            }
          : {
              position: 'fixed',
              left: '0px',
              top: '0px',
              width: '100vw',
              height: '100vh',
              background: `rgba(0,0,0,${props.opacity})`,
              zIndex: '9999999',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ...props.containerStyle,
            }
      }>
      <CircularProgress {...props.spinnerProps} />
    </Box>
  )
}

export default Loader
