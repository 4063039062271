import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: false,
}

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.value = true
    },
    hideLoader: (state) => {
      state.value = false
    },
  },
})

export const loaderSelector = (state) => state.loader.value
export const loaderSliceActions = loaderSlice.actions
export default loaderSlice.reducer
