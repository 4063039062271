import axios from '../axios'

const base = '/base-data'

export const getCountries = () => {
  return axios.get(`${base}/countries/`)
}
export const getLanguages = () => {
  return axios.get(`${base}/languages/`)
}
export const getTimeZones = () => {
  return axios.get(`${base}/timezones/`)
}
export const getKeywords = (query) => {
  return axios.get(`${base}/keywords/${query}/`)
}
export const createKeyword = (title) => {
  return axios.post(`${base}/keywords/`, { title })
}
