import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
// TODO: Load lazy
import FileInputWithValidation from '../global/FileInputWithValidation'
import SelectWithValidation from '../global/SelectWithValidation'
import TextFieldWithValidation from '../global/TextFieldWithValidation'
import DatepickerWithValidation from '../global/DatepickerWithValidation'
import CheckboxWithValidation from '../global/CheckboxWithValidation'
// import RichTextEditorWithValidation from '../global/RichTextEditorWithValidation'
const RichTextEditorWithValidation = isDashboard()
  ? require('../global/RichTextEditorWithValidation')
  : require('../../../components/global/RichTextEditorWithValidationSSR')
import ToggleButtonWithValidation from '../global/ToggleButtonWithValidation'
import { useFieldArray } from 'react-hook-form'
import { AddOutlined, RemoveOutlined } from '@mui/icons-material'
import { useEffect, useMemo, useRef, useState } from 'react'
import isDashboard from '../../utils/isDashboard'
import isServer from '../../utils/isServer'
import { useSelector } from 'react-redux'
import AutocompleteWithValidation from '../global/AutocompleteWithValidation'
import RadioGroupWithValidation from '../global/RadioGroupWithValidation'
import SliderInputWithValidation from '../global/SliderInputWithValidation'
import TimepickerWithValidation from '../global/TimepickerWithValidation'
import resolve from '../../utils/resolve'
import RemoveWithConfirmation from '../global/RemoveWithConfirmation'
import DateTimepickerWithValidation from '../global/DateTimepickerWithValidation'
import SwitchWithValidation from '../global/SwitchWithValidation'
import AutocompleteAsyncWithValidation from '../global/AutocompleteAsyncWithValidation'

let basedataSelector
if (isDashboard()) {
  basedataSelector =
    require('../../../store/slices/basedataSlice').basedataSelector
}

const FieldArrayHandler = (props) => {
  const { fields: parentFields, ...parentProps } = props.parentProps
  const { fields, append, remove } = useFieldArray({
    control: parentProps.control,
    name: props.name,
  })

  const dynamicFields = useMemo(() => {
    const temp = []
    fields.forEach((item, index) => {
      props.fields(index).forEach((sub) => {
        temp.push({
          ...sub,
          id: item.id,
          name: `${props.name}.${index}.${sub.name}`,
        })
      })
    })
    return temp
  }, [fields])

  useEffect(() => {
    if (props.rules) {
      const rules = Object.keys(props.rules)
      rules.forEach((rule) => {
        switch (rule) {
          case 'minLength':
            if (fields.length < props.rules.minLength) {
              parentProps.setError(props.name, {
                type: 'minLength',
              })
            } else {
              parentProps.clearErrors('minLength')
            }
            break
          case 'maxLength':
            break
          case 'required':
            break
          case 'validate':
            break
          default:
            break
        }
      })
    }
  }, [dynamicFields])

  const handleAppend = () => {
    let defaultFields = {}
    props.fields(fields.length).forEach((f) => {
      if (f.name) {
        defaultFields[f.name] = f.defaultValue
        defaultFields[`${props.name}.${fields.length}.${f.name}`] =
          f.defaultValue
      }
    })
    append(defaultFields)
  }

  const errors = parentProps.errors

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormFactory
          fields={dynamicFields}
          keyWord={props.keyWord}
          {...parentProps}
          onAdd={handleAppend}
          onRemove={remove}
          dynamicForm
        />
      </Grid>
      <Grid item xs={12}>
        <Typography color="error">
          {errors[props.name] ? props.helperText[errors[props.name].type] : ''}
        </Typography>
      </Grid>
    </Grid>
  )
}

const FormFactory = (props) => {
  // types
  // title => Title
  // divider => Divider
  // empty => Space
  // 0 => text
  // 1 => number
  // 2 => email
  // 3 => textarea
  // 4 => file
  // 5 => select
  // 6 => URL
  // 7 => datepicker
  // 8 => checkbox
  // 9 => rich text editor
  // 10 => toggle button
  // 11 => price
  // 12 => dynamic fields
  // 13 => autocomplete
  // 14 => radio group
  // 15 => slider
  // 16 => timepicker
  // 17 => datetimepicker
  // 18 => switch
  // 19 => async autocomplete

  let basedata = useSelector((state) => {
    if (isDashboard()) {
      return basedataSelector(state)
    } else {
      return props.basedata
    }
  })

  const rules = (field) => {
    return typeof field.rules == 'function'
      ? field.rules(props.values)
      : field.rules
  }

  return (
    <Box
      component={
        isDashboard() && location.pathname.includes('wizard') ? Container : Box
      }
      {...props.containerProps}>
      <Grid container {...props.gridContainerProps}>
        {props.fields.map((field, index) => {
          if (
            field.renderCondition !== undefined &&
            !field.renderCondition(props.values)
          ) {
            return
          }
          return (
            <Grid
              item
              sx={{
                paddingTop: '0px !important',
              }}
              {...props.gridItemProps}
              {...field.gridItemProps}
              key={props.dynamicForm ? field.id + index : field.name + index}>
              {field.type === 'title' && (
                <Typography {...field.typographyProps}>{field.text}</Typography>
              )}
              {field.type === 'divider' && (
                <Divider {...field.dividerProps}></Divider>
              )}
              {field.type === 'custom' && props[field.slotKey]()}

              <Box display="flex" alignItems="start" justifyContent="center">
                {(field.type === 0 ||
                  field.type === 1 ||
                  field.type === 2 ||
                  field.type === 3 ||
                  field.type === 6 ||
                  field.type === 11) && (
                  <TextFieldWithValidation
                    name={field.name}
                    label={field.label}
                    placeholder={field.placeholder}
                    control={props.control}
                    rules={
                      field.type === 6
                        ? {
                            ...rules(field),
                            validate: (val) =>
                              !val ||
                              val.length === 0 ||
                              /^(http|https):\/\/[^ "]+$/.test(val),
                          }
                        : field.type === 2
                        ? {
                            ...rules(field),
                            validate: (val) =>
                              !val ||
                              val.length === 0 ||
                              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                val
                              ),
                          }
                        : rules(field)
                    }
                    helperText={
                      field.type === 6
                        ? {
                            ...field.helperText,
                            validate: 'This URL is not correct',
                          }
                        : field.type === 2
                        ? {
                            validate: 'This email is not correct',
                            ...field.helperText,
                          }
                        : field.helperText
                    }
                    margin={field.margin}
                    multiline={field.type === 3}
                    rows={field.type === 3 ? undefined : 1}
                    minRows={field.type === 3 ? 4 : undefined}
                    maxRows={field.type === 3 ? 20 : undefined}
                    type={['text', 'number', 'email'][field.type]}
                    price={field.type === 11}
                    {...field.inputProps}
                  />
                )}

                {field.type === 4 && (
                  <FileInputWithValidation
                    name={field.name}
                    label={field.label}
                    placeholder={field.placeholder}
                    control={props.control}
                    rules={rules(field)}
                    helperText={field.helperText}
                    margin={field.margin}
                    multiple={field.multiple}
                    accept={field.accept}
                  />
                )}

                {field.type === 5 && (
                  <SelectWithValidation
                    name={field.name}
                    control={props.control}
                    rules={rules(field)}
                    helperText={field.helperText}
                    label={field.label}
                    noResultsText={field.noResultsText}
                    items={
                      field.itemsByBasedata
                        ? basedata[field.itemsByBasedata]
                        : field.itemsByProp
                        ? resolve(field.itemsByProp, props)
                        : field.items
                    }
                    asVal={field.asVal}
                    margin={field.margin}
                  />
                )}

                {field.type === 7 && (
                  <DatepickerWithValidation
                    name={field.name}
                    label={field.label}
                    placeholder={field.placeholder}
                    control={props.control}
                    rules={rules(field)}
                    helperText={field.helperText}
                    margin={field.margin}
                    localeText={field.localeText}
                  />
                )}

                {field.type === 8 && (
                  <CheckboxWithValidation
                    name={field.name}
                    label={field.label}
                    control={props.control}
                    rules={rules(field)}
                    helperText={field.helperText}
                    margin={field.margin}
                  />
                )}

                {field.type === 9 && (
                  <RichTextEditorWithValidation
                    name={field.name}
                    label={field.label}
                    control={props.control}
                    rules={rules(field)}
                    helperText={field.helperText}
                    placeholder={field.placeholder}
                  />
                )}

                {field.type === 10 && (
                  <ToggleButtonWithValidation
                    name={field.name}
                    label={field.label}
                    control={props.control}
                    rules={rules(field)}
                    items={field.items}
                  />
                )}

                {field.type === 13 && (
                  <AutocompleteWithValidation
                    name={field.name}
                    control={props.control}
                    rules={rules(field)}
                    helperText={field.helperText}
                    label={field.label}
                    placeholder={field.placeholder}
                    margin={field.margin}
                    asVal={field.asVal}
                    multiple={field.multiple}
                    noResultsText={field.noResultsText}
                    options={
                      field.itemsByBasedata
                        ? basedata[field.itemsByBasedata]
                        : field.itemsByProp
                        ? resolve(field.itemsByProp, props)
                        : field.items
                    }
                  />
                )}

                {field.type === 14 && (
                  <RadioGroupWithValidation
                    name={field.name}
                    control={props.control}
                    rules={rules(field)}
                    helperText={field.helperText}
                    label={field.label}
                    asVal={field.asVal}
                    options={
                      field.itemsByBasedata
                        ? basedata[field.itemsByBasedata]
                        : field.itemsByProp
                        ? resolve(field.itemsByProp, props)
                        : field.items
                    }
                  />
                )}

                {field.type === 15 && (
                  <SliderInputWithValidation
                    name={field.name}
                    control={props.control}
                    rules={rules(field)}
                    helperText={field.helperText}
                    label={field.label}
                    minLabel={field.minLabel}
                    maxLabel={field.maxLabel}
                    min={field.min}
                    max={field.max}
                    step={field.step}
                  />
                )}

                {field.type === 16 && (
                  <TimepickerWithValidation
                    name={field.name}
                    label={field.label}
                    placeholder={field.placeholder}
                    control={props.control}
                    rules={rules(field)}
                    helperText={field.helperText}
                    margin={field.margin}
                    localeText={field.localeText}
                  />
                )}

                {field.type === 17 && (
                  <DateTimepickerWithValidation
                    name={field.name}
                    label={field.label}
                    placeholder={field.placeholder}
                    control={props.control}
                    rules={rules(field)}
                    helperText={field.helperText}
                    margin={field.margin}
                    localeText={field.localeText}
                  />
                )}

                {field.type === 18 && (
                  <SwitchWithValidation
                    name={field.name}
                    label={field.label}
                    control={props.control}
                    rules={rules(field)}
                  />
                )}

                {field.type === 19 && (
                  <AutocompleteAsyncWithValidation
                    name={field.name}
                    control={props.control}
                    rules={rules(field)}
                    helperText={field.helperText}
                    label={field.label}
                    placeholder={field.placeholder}
                    margin={field.margin}
                    asVal={field.asVal}
                    multiple={field.multiple}
                    noResultsText={field.noResultsText}
                    endpoint={field.endpoint}
                    createEndpoint={field.createEndpoint}
                  />
                )}

                {props.dynamicForm &&
                  field.remove !== undefined &&
                  field.remove(props.values) && (
                    // <IconButton
                    //   variant="outline"
                    //   color="error"
                    //   onClick={() => props.onRemove(field.index)}>
                    //   <RemoveOutlined />
                    // </IconButton>
                    // <Button
                    //   color="error"
                    //   onClick={() => props.onRemove(field.index)}
                    //   variant="contained"
                    //   {...field.buttonProps}
                    //   startIcon={<RemoveOutlined />}>
                    //   Remove item
                    // </Button>
                    <RemoveWithConfirmation
                      buttonProps={{
                        ...field.buttonProps,
                        startIcon: <RemoveOutlined />,
                        variant: 'contained',
                      }}
                      onAccept={() => props.onRemove(field.index)}
                    />
                  )}
              </Box>

              {field.type === 12 && (
                <FieldArrayHandler {...field} parentProps={props} />
              )}

              {field.description && (
                <Typography {...field.typographyProps}>
                  {field.description}
                </Typography>
              )}
            </Grid>
          )
        })}

        {props.dynamicForm && (
          <Grid item xs={12} md={4} pb={1} display="flex" alignItems="center">
            <Button startIcon={<AddOutlined />} onClick={props.onAdd}>
              Add {props.keyWord}
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

FormFactory.defaultProps = {
  containerProps: { fixed: true },
  gridContainerProps: { spacing: 2 },
  gridItemProps: {
    xs: 12,
    md: 4,
  },
}

export default FormFactory
