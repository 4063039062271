import { Checkbox, FormControlLabel } from '@mui/material'
import { Controller } from 'react-hook-form'

const CheckboxWithValidation = (props) => {
  const { name, control, rules, helperText, label } = props

  return (
    <FormControlLabel
      label={label}
      control={
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({
            field,
            fieldState: { invalid, isTouched, isDirty, error },
          }) => (
            <Checkbox
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
              name={name}
            />
          )}
        />
      }
    />
  )
}

// using example

export default CheckboxWithValidation
