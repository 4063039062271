import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { useState } from 'react'

const ButtonWithConfirmation = ({
  buttonText,
  dialogTitle,
  dialogDescription,
  onAccept,
  buttonProps,
  ButtonIcon,
  icon = false,
  acceptText = 'Accept',
  cancelText = 'Cancel',
}) => {
  const [dialogStatus, setDialogStatus] = useState(false)
  return (
    <>
      <Dialog
        open={dialogStatus}
        onClose={() => setDialogStatus(false)}
        data-no-dnd="true">
        <DialogTitle fontWeight="bold">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogDescription}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogStatus(false)}>{cancelText}</Button>
          <Button
            onClick={() => {
              setDialogStatus(false)
              onAccept()
            }}>
            {acceptText}
          </Button>
        </DialogActions>
      </Dialog>
      {icon ? (
        <IconButton {...buttonProps} onClick={() => setDialogStatus(true)}>
          {ButtonIcon}
        </IconButton>
      ) : (
        <LoadingButton onClick={() => setDialogStatus(true)} {...buttonProps}>
          {buttonText}
        </LoadingButton>
      )}
    </>
  )
}

export default ButtonWithConfirmation
