import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.data = payload
    },
  },
})

export const userSelector = (state) => state.user
export const userSliceActions = userSlice.actions
export default userSlice.reducer
