import { useTranslations } from './translationContext'
// import en from './en.json'

export const useTranslate = (_translations) => {
  const translations = useTranslations() || _translations || []
  const handler = (key) => {
    return (
      translations[key] ||
      (Array.isArray(translations) &&
        translations?.find((item) => item.word === key)?.translate) ||
      // en[key] ||
      key
    )
  }

  return handler
}
