import { useTranslations } from './translationContext'

export const useTranslate = () => {
  const translations = useTranslations() || {}

  const handler = (key) => {
    return translations[key] || key
  }

  return handler
}
