import { Avatar, Box, Grid, Typography } from '@mui/material'
import { colors } from '../../styles/theme'
// import { colors } from '../../shared/styles/theme'

const radius = '20px'
const size = 4
const rightBgColor = colors.primary.main
const classes = {
  avatar: {
    width: size,
    height: size,
  },
  leftRow: {
    textAlign: 'left',
  },
  rightRow: {
    textAlign: 'left',
  },
  msg: {
    py: 1,
    px: 1.2,
    borderRadius: '4px',
    marginBottom: 0.5,
    display: 'inline-block',
    wordBreak: 'break-word',
    // width: '100%',
    fontSize: '16px',
    lineHeight: '1.6',
    whiteSpace: 'pre-line',
  },
  left: {
    borderTopRightRadius: radius,
    borderBottomRightRadius: radius,
    backgroundColor: colors.grey[100],
  },
  right: {
    borderTopLeftRadius: radius,
    borderBottomLeftRadius: radius,
    backgroundColor: rightBgColor,
    color: colors.common.white,
  },
  leftFirst: {
    borderTopLeftRadius: radius,
  },
  leftLast: {
    borderBottomLeftRadius: radius,
  },
  rightFirst: {
    borderTopRightRadius: radius,
  },
  rightLast: {
    borderBottomRightRadius: radius,
  },
}

const Message = (props) => {
  // const classes = styles()
  const {
    // classes,
    avatar,
    message,
    side,
    GridContainerProps,
    GridItemProps,
    AvatarProps,
    first,
    last,
    typing,
    Append,
  } = props

  const attachClass = () => {
    if (first) {
      return classes[`${side}First`]
    }
    if (last) {
      return classes[`${side}Last`]
    }
    return ''
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{
        // marginBottom: 4,
        justifyContent: side === 'right' ? 'flex-end' : 'flex-start',
      }}
      {...GridContainerProps}>
      {/* {side === 'left' && (
        <Grid item {...GridItemProps}>
          <Avatar
            src={avatar}
            {...AvatarProps}
            sx={[classes.avatar, AvatarProps.className]}
          />
        </Grid>
      )} */}
      <Grid
        item
        xs={9}
        {...GridItemProps}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: side === 'right' ? 'flex-end' : 'flex-start',
          ...(last && {
            marginBottom: 1.5,
          }),
        }}>
        {((!typing && message && message.length > 0) || typing) && (
          <Typography
            sx={[
              classes.msg,
              classes[side],
              attachClass(),
              classes[`${side}Row`],
            ]}>
            {typing ? (
              <span className="bouncing-loader">
                <span></span>
                <span></span>
                <span></span>
              </span>
            ) : (
              message
            )}
          </Typography>
        )}
        {Append && Append}
      </Grid>
    </Grid>
  )
}

Message.defaultProps = {
  avatar: '',
  message: '',
  //   side: 'left',
  GridContainerProps: {},
  GridItemProps: {},
  AvatarProps: {},
}

export default Message
