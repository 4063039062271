import { ArrowDropDown, Translate } from '@mui/icons-material'
import { Button, Menu, MenuItem } from '@mui/material'
import { languages } from '../../../locale/translationContext'
import { selectedLang, setSelectedLang } from '../../../shared/utils/auth'
import { useState } from 'react'

const I18n = ({ landing }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleChange = (lang) => {
    if (lang.code === selectedLang()) return
    setSelectedLang(lang.code)
    location.reload()
  }
  if (process.env.REACT_APP_LANG_SWITCHER !== 'true') return <></>
  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOpen}
        endIcon={<Translate />}
        size={landing ? undefined : 'small'}
        sx={{ borderRadius: landing ? 0 : undefined }}
        startIcon={<ArrowDropDown sx={{ color: 'black' }} />}>
        {languages[selectedLang()].title}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {Object.values(languages).map((lang) => (
          <MenuItem
            onClick={() => handleChange(lang)}
            key={lang.code}
            sx={{ minWidth: '100px' }}>
            {lang.title || lang.code}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
export default I18n
