import { Controller, useController } from 'react-hook-form'
import FileInput from './FileInput'

const FileInputWithValidation = (props) => {
  const { name, control, rules, ...fileInputProps } = props

  const {
    field: { onChange, onBlur, ref, value },
    fieldState,
  } = useController({
    name,
    control,
    rules,
  })

  return (
    <FileInput
      rules={rules}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      handleRef={ref}
      error={fieldState.error}
      {...fileInputProps}
    />
  )
}

export default FileInputWithValidation
