import {
  Box,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  Paper,
  ThemeProvider,
  Toolbar,
  useMediaQuery,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath, useLocation, useNavigate } from 'react-router'
import { setToken } from '../../../api/axios'
import { getUserProfile } from '../../../api/lib/user'
import routeNames from '../../../router/routeNames'
import { userActions } from '../../../store/store'
import isAuthenticated, {
  getAccessToken,
  setAccessToken,
} from '../../../shared/utils/auth'
import Navbar from './Navbar'
import { breakpoints } from '../../../styles/theme'
import { useTranslate } from '../../../locale/useTranslateHook'
import LoadOrError from '../../../shared/components/global/LoadOrError'
import { knowledgeSliceActions } from '../../../store/slices/knowledgeSlice'
import { agentSliceActions } from '../../../store/slices/agentSlice'

let toNext = ''
const Layout = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [mounted, setMounted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [navbarStatus, setNavbarStatus] = useState([])
  const [navbarRenderStatus, setNavbarRenderStatus] = useState()
  const mobile = useMediaQuery(`(max-width: ${breakpoints.sm}px)`)

  const t = useTranslate()

  // const fluidContainer = location.pathname === routeNames.wizard
  // const fluidContainer = false
  const fluidContainer =
    matchPath(routeNames.agent.pattern, location.pathname) &&
    !matchPath(routeNames.agentsList, location.pathname)

  const getProfile = () => {
    setLoading(true)
    setError(null)
    getUserProfile()
      .then((response) => {
        dispatch(userActions.setUser(response.data))
        if (toNext.length > 0) navigate(toNext)
        setLoading(false)
        setMounted(true)
      })
      .catch(() => {
        setLoading(false)
        setError(t('Error occurred.'))
      })
  }

  useEffect(() => {
    if (isAuthenticated()) {
      setToken(getAccessToken())
      dispatch(knowledgeSliceActions.getKnowledges())
      dispatch(agentSliceActions.getAgents())
      getProfile()
    } else setMounted(true)
  }, [])

  const isChatPage =
    matchPath(routeNames.talk.pattern, location.pathname) ||
    matchPath(routeNames.talkDetail.pattern, location.pathname)
  const isLandingPage = false
  // matchPath(routeNames.landing, location.pathname) ||
  // matchPath(routeNames.edadLanding, location.pathname)
  const noLayout = isChatPage || isLandingPage

  if (noLayout)
    return (
      <>
        {!mounted && (
          <LoadOrError retry={getProfile} loading={loading} error={error} />
        )}
        {mounted && props.children}
      </>
    )

  return (
    <>
      <Box
        sx={{
          display: (navbarRenderStatus && 'flex') || undefined,
          flexDirection: 'row-reverse',
        }}>
        <Navbar
          isLoadingUserData={!mounted}
          onUpdate={setNavbarStatus}
          navbarRenderStatus={setNavbarRenderStatus}
          fluidContainer={fluidContainer}>
          <Box
            component="main"
            sx={{
              // backgroundColor: (theme) =>
              //   theme.palette.mode === 'light'
              //     ? theme.palette.grey[100]
              //     : theme.palette.grey[900],
              flexGrow: 1,
              // height: '100vh',
              // overflow: 'auto',
              display:
                navbarStatus.every((status) => status) && mobile
                  ? 'none'
                  : 'block',
            }}>
            {navbarRenderStatus && <Toolbar />}
            <Container
              maxWidth={fluidContainer ? false : 'lg'}
              // sx={{ mt: 4, mb: 4 }}>
            >
              {!mounted && (
                <LoadOrError
                  retry={getProfile}
                  loading={loading}
                  error={error}
                />
              )}
              {mounted && props.children}
            </Container>
          </Box>
        </Navbar>
      </Box>
    </>
  )
}

export default Layout
