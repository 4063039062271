import { Close } from '@mui/icons-material'
import {
  Autocomplete as AutocompleteField,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { useState } from 'react'

const filter = createFilterOptions()

const Autocomplete = (props) => {
  const {
    multiple,
    options,
    getOptionLabel,
    label,
    placeholder,
    margin,
    filterOptions,
    onChange,
    value,
    isOptionEqualToValue,
    error,
    helperText,
    textFieldProps,
    required,
    noResultsText,
    ...otherProps
  } = props
  return (
    <AutocompleteField
      multiple={multiple}
      limitTags={3}
      options={options}
      disableCloseOnSelect={multiple}
      getOptionLabel={getOptionLabel}
      noOptionsText={noResultsText}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          margin={margin}
          error={error}
          helperText={
            <>
              {error && (
                <Typography
                  color="error"
                  variant="caption"
                  sx={{ display: 'block' }}>
                  {helperText[error.type]}
                </Typography>
              )}
              {helperText.helper && (
                <Typography color="text.secondary" variant="caption">
                  {helperText.helper}
                </Typography>
              )}
            </>
          }
          required={required}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'off',
            endAdornment: (
              <>
                {otherProps?.loading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)
        return filterOptions(options, params, filtered)
      }}
      onChange={(e, val, reason) => {
        onChange(val, reason)
      }}
      value={value}
      {...otherProps}
    />
  )
}

Autocomplete.defaultProps = {
  margin: 'none',
  fullWidth: true,
  error: false,
  helperText: '',
  onBlur: () => {},
  required: false,
  multiple: false,
  filterOptions: (options, params, filtered) => filtered,
  getOptionLabel: (option) => option.title,
  isOptionEqualToValue: (option, value) => option.id === value.id,
  options: [],
  noResultsText: 'No results',
}

export default Autocomplete
