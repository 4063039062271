import { useEffect, useRef, useState } from 'react'
import {
  Box,
  ButtonBase,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { DeleteOutline } from '@mui/icons-material'

const FileInput = (props) => {
  const {
    name,
    rules,
    helperText,
    accept,
    multiple,
    value,
    onChange,
    onBlur,
    handleRef,
    error,
    onRemove,
    disabled,
    notUploaded,
    ...textFieldProps
  } = props

  const hiddenFileInput = useRef(null)
  const handleClick = (event) => {
    event.target.value = null
    hiddenFileInput.current.click()
  }
  const [valueFiles, setValueFiles] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])

  useEffect(() => {
    if (value) {
      let defaultVal = value
      if (!Array.isArray(defaultVal)) defaultVal = [defaultVal]
      let files = defaultVal.map((item) => {
        if (item.name) return item
        return {
          file: item,
          name: item.substring(item.lastIndexOf('/') + 1),
        }
      })
      setValueFiles(files)
      if (!notUploaded) setUploadedFiles(files)
    }
  }, [])

  const handleFiles = (files) => {
    if (!files) return
    files = files.length > 0 ? [...files] : [files]
    let checkedFiles = files.filter(
      (file) => !valueFiles.some((i) => i.name === file.name)
    )
    if (checkedFiles.length > 0) {
      let checkedValueFiles = valueFiles.filter(
        (file) => !uploadedFiles.some((i) => i.name === file.name)
      )
      if (multiple) {
        setValueFiles([...checkedValueFiles, ...checkedFiles])
        // setUploadedFiles([])
        onChange([...checkedValueFiles, ...checkedFiles])
      } else {
        setValueFiles(checkedFiles)
        // setUploadedFiles([])
        onChange(checkedFiles)
      }
    }
  }

  const removeFile = (index) => {
    const uploadedItem = uploadedFiles.findIndex((item) =>
      item.file.includes(valueFiles[index].name)
    )
    if (uploadedItem != '-1') {
      onRemove(uploadedItem)
    } else {
      let files = valueFiles
      files.splice(index, 1)
      setValueFiles(files)
      onChange(files)
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <ButtonBase
        disabled={disabled}
        onClick={handleClick}
        onBlur={onBlur}
        sx={{ width: '100%' }}>
        <TextField
          error={Boolean(error)}
          helperText={error ? helperText[error.type] : ''}
          required={rules.required}
          // value={value}
          {...textFieldProps}
          // onBlur={onBlur}
          //   label={textFieldProps.label}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          value={`Click to select file${multiple ? 's' : ''}`}
          disabled={disabled}
        />
        {/* <Box
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '10px',
            width: '100%',
            minHeight: '56px',
          }}>
          <Typography sx={{ py: 2, px: 1.75 }}>
            Click to add files {rules.required ? '*' : ''}
          </Typography>
        </Box> */}
        <input
          name={name}
          ref={(el) => {
            handleRef(el)
            hiddenFileInput.current = el
          }}
          type="file"
          accept={accept}
          hidden
          onChange={(e) => handleFiles(e.target.files)}
          multiple={multiple}
          id={name}
        />
      </ButtonBase>
      {/* <Typography
        color="error"
        variant="caption"
        sx={{ display: 'block', mt: 0.375, mx: 1.75 }}>
        {error ? helperText[error.type] : ''}
      </Typography> */}
      <Box sx={{ px: 1 }}>
        {valueFiles.map((item, index) => (
          <Box display="flex" alignItems="center" key={'file' + index}>
            {uploadedFiles.some((up) => up.file.includes(item.name)) ? (
              <a href={item.file} target="_blank">
                <Typography sx={{ wordBreak: 'break-all' }}>
                  {item.name}
                </Typography>
              </a>
            ) : (
              <Typography sx={{ wordBreak: 'break-all' }}>
                {item.name}
              </Typography>
            )}
            <IconButton
              onClick={() => {
                if (multiple) removeFile(index)
                else {
                  setValueFiles([])
                  onChange([])
                }
              }}>
              <DeleteOutline />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

FileInput.defaultProps = {
  accept: '*',
  multiple: false,
  name: 'name',
  rules: {},
  helperText: {},
  onRemove: () => {},
  onBlur: () => {},
  handleRef: () => {},
  error: false,
  disabled: false,
  notUploaded: false,
}

export default FileInput
