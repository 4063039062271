import { Suspense, useMemo } from 'react'
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter,
  useParams,
} from 'react-router-dom'

import { SnackbarProvider } from 'notistack'

import routes from './routes'
import isAuthenticated from '../shared/utils/auth'
import routeNames from './routeNames'
import { useSelector } from 'react-redux'
import Layout from '../components/global/layout/Index'
import { loaderSelector } from '../store/slices/loaderSlice'
import { userSelector } from '../store/slices/userSlice'
import Loader from '../shared/components/global/Loader'

const Redirect = ({ to }) => {
  const params = useParams()

  const newRoute = (to, params) => {
    const entries = Object.entries(params)
    let path = `${to}`

    entries.forEach(([key, value]) => {
      path = path.replace(`:${key}`, `${value}`)
    })

    return path
  }

  return <Navigate to={newRoute(to, params)} replace />
}

const RouterRenderer = () => {
  const loading = useSelector(loaderSelector)
  const userState = useSelector(userSelector)

  const usageData = useMemo(() => {
    return userState.data?.businessObject?.usage_data || []
  }, [JSON.stringify(userState.data?.businessObject?.usage)])

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      {loading && <Loader opacity={0.3} />}
      <SnackbarProvider autoHideDuration={3000}>
        <Layout>
          <Suspense fallback={<Loader opacity={0} Local />}>
            <Routes>
              {routes.map(
                ({
                  path,
                  Main,
                  authRequired,
                  redirect,
                  notAuthenticated,
                  render,
                  children,
                }) => {
                  const validated =
                    render === undefined ||
                    (typeof render === 'function' && render(usageData))
                  if (validated === true)
                    return (
                      <Route
                        key={path}
                        path={path}
                        element={
                          authRequired && !isAuthenticated() ? (
                            <Navigate
                              to={
                                routeNames.login +
                                (path !== routeNames.homepage &&
                                path !== routeNames.logout
                                  ? '?next=' + path
                                  : '')
                              }
                            />
                          ) : notAuthenticated && isAuthenticated() ? (
                            <Navigate to={routeNames.homepage} />
                          ) : redirect ? (
                            <Redirect to={redirect} />
                          ) : (
                            <Main key={path} />
                          )
                        }>
                        {children &&
                          children.map((subRoute) => (
                            <Route
                              key={subRoute.path}
                              path={subRoute.path}
                              element={<subRoute.Main key={subRoute.path} />}
                            />
                          ))}
                      </Route>
                    )
                  else return
                }
              )}
            </Routes>
          </Suspense>
        </Layout>
      </SnackbarProvider>
    </BrowserRouter>
  )
}

export default RouterRenderer
