import { Box, TextField, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import currencyFormat from '../../utils/currencyFormat'

const TextFieldWithValidation = (props) => {
  const { name, control, rules, helperText, price, ...textFieldProps } = props

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field,
        fieldState: { invalid, isTouched, isDirty, error },
      }) => (
        <TextField
          //   error={(invalid || error) && (isTouched || isDirty)}
          error={Boolean(error)}
          FormHelperTextProps={{ component: 'div', sx: { color: 'auto' } }}
          helperText={
            <Box>
              {price && currencyFormat(field.value).length > 0 && (
                <Typography color="text.secondary" variant="caption">
                  {currencyFormat(field.value)}
                </Typography>
              )}
              {error && (
                <Typography
                  color="error"
                  variant="caption"
                  sx={{ display: 'block' }}>
                  {helperText[error.type]}
                </Typography>
              )}
              {helperText.helper && (
                <Typography color="text.secondary" variant="caption">
                  {helperText.helper}
                </Typography>
              )}
            </Box>
          }
          required={rules.required}
          {...field}
          {...textFieldProps}
        />
      )}
    />
  )
}

TextFieldWithValidation.defaultProps = {
  margin: 'none',
  variant: 'outlined',
  fullWidth: true,
  helperText: '', // can pass an object with helper property to show by default
  rules: {},
  multiline: false,
}

// using example

export default TextFieldWithValidation
