import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAgents as getAgentsList } from '../../api/lib/agent'

const getAgents = createAsyncThunk('getAgents', async (_, thunkApi) => {
  const response = await getAgentsList()
  return response.data
})

const initialState = {}

export const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    setAgents: (state, { payload }) => {
      state.data = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAgents.pending, (state, action) => {
        state.error = false
        state.loading = true
      })
      .addCase(getAgents.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(getAgents.rejected, (state, action) => {
        state.loading = false
        state.error = true
      })
  },
})

export const agentSelector = (state) => state.agent?.data || { results: [] }
export const agentSliceActions = {
  ...agentSlice.actions,
  getAgents,
}
export default agentSlice.reducer
