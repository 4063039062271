import {
  CONTENT_TYPE,
  READY_STATUS,
  CONTENT_TYPE_AUDIO,
  ANSWER_QUALITY,
  TALK_SIDE_USER,
  CONDITION_TYPE_CUSTOM,
  TRANSITION_TO_PAGE,
  REQUEST_METHOD,
  ACCESS_LEVEL,
  TRANSITION_TO,
  CONDITION_TYPE,
} from './consts'

export const getContentType = (id) => CONTENT_TYPE.find((item) => item.id == id)
export const isContentAudio = (id) => id == CONTENT_TYPE_AUDIO

export const getContentStatus = (id) =>
  READY_STATUS.find((item) => item.id == id)

export const getAnswerQuality = (id) =>
  ANSWER_QUALITY.find((item) => item.id == id)

export const isMessageFromUser = (id) => TALK_SIDE_USER == id

export const isConditionTypeCustom = (id) => CONDITION_TYPE_CUSTOM == id

export const isTransitionToPage = (id) => TRANSITION_TO_PAGE == id

export const getRequestMethod = (id) =>
  REQUEST_METHOD.find((item) => item.id == id)

export const getAccessLevel = (id) => ACCESS_LEVEL.find((item) => item.id == id)

export const getTransitionTo = (id) =>
  TRANSITION_TO.find((item) => item.id == id)

export const getConditionType = (id) =>
  CONDITION_TYPE.find((item) => item.id == id)
