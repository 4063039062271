import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  getCountries,
  getLanguages,
  getTimeZones,
} from '../../api/lib/basedata'

const apis = {
  countries: getCountries,
  languages: getLanguages,
  timezones: getTimeZones,
}

const getBasedata = createAsyncThunk(
  'getBasedata',
  async ({ key, payload }, thunkApi) => {
    const response = await apis[key](payload)
    return {
      data: response.data,
      key,
    }
  }
)

const initialState = {}

export const basedataSlice = createSlice({
  name: 'basedata',
  initialState,
  reducers: {
    setBasedata: (state, { payload }) => {
      state[payload.name] = payload.data
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBasedata.pending, (state, action) => {
        state.error = false
        state.loading = true
      })
      .addCase(getBasedata.fulfilled, (state, action) => {
        state.loading = false
        state[action.payload.key] = action.payload.data
      })
      .addCase(getBasedata.rejected, (state, action) => {
        state.loading = false
        state.error = true
      })
  },
})

export const basedataSelector = (state) => state.basedata
export const { setBasedata } = basedataSlice.actions
export const basedataSliceActions = { ...basedataSlice.actions, getBasedata }
export default basedataSlice.reducer
