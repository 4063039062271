import { useEffect, useRef, useState } from 'react'
import {
  Box,
  ButtonBase,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const RichTextEditor = (props) => {
  const {
    name,
    rules,
    helperText,
    accept,
    multiple,
    value,
    onChange,
    onBlur,
    handleRef,
    error,
    label,
    placeholder,
    ...editorProps
  } = props

  const [val, setVal] = useState('')
  const [editor, setEditor] = useState('')

  useEffect(() => {
    if (value) {
      setVal(value)
    } else if (placeholder) {
      setVal(placeholder)
    }
  }, [])

  const handleChange = (event, editor) => {
    const data = editor.getData()
    setVal(data)
    onChange(data)
  }

  const onReady = (editor) => {
    setEditor(editor)
    editor.editing.view.change((writer) => {
      writer.setStyle('height', '200px', editor.editing.view.document.getRoot())
    })
  }

  return (
    <Box sx={{ width: '100%', mt: 1 }}>
      <Typography>{label}</Typography>
      <CKEditor
        editor={ClassicEditor}
        data={val}
        onReady={onReady}
        onChange={handleChange}
        onBlur={onBlur}
        ref={handleRef}
        config={{
          toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'blockQuote',
            'insertTable',
            'mediaEmbed',
          ],
        }}
        {...editorProps}
      />
      {error && (
        <Typography
          color="error"
          variant="caption"
          sx={{ display: 'block', mt: 0.375, mx: 1.75 }}>
          {helperText[error.type]}
        </Typography>
      )}
      {helperText.helper && (
        <Typography
          color="text.secondary"
          variant="caption"
          sx={{ display: 'block', mt: 0.375, mx: 1.75 }}>
          {helperText.helper}
        </Typography>
      )}
    </Box>
  )
}

RichTextEditor.defaultProps = {
  name: 'name',
  rules: {},
  helperText: {},
  onRemove: () => {},
  onBlur: () => {},
  handleRef: () => {},
  error: false,
}

export default RichTextEditor
