import { useEffect, useState } from 'react'
import FormFactory from './FormFactory'
import { useTranslate } from '../../locales/useTranslateHook'
const forms = require('../../../components/formBuilder/formsFields')

const FormBuilder = (props) => {
  const t = useTranslate()
  const required = t('This field is required.')

  const general = { required }

  const [fields, setFields] = useState([])

  useEffect(() => {
    forms[props.formName](props, general).then(setFields)
  }, [])

  const watchValues = props.control._getWatch()

  return <FormFactory fields={fields} values={watchValues} {...props} /> // control prop is required
  // you need to have
  // setError
  // clearErrors
  // errors
  // props if you want to use dynamic fields
}

export default FormBuilder
