import { SendOutlined } from '@mui/icons-material'
import { Box, Chip, IconButton, InputAdornment, TextField } from '@mui/material'
import Loader from '../global/Loader'
import isRTL from '../../../utils/isRTL'

const MessageField = ({
  text,
  setText,
  onSend,
  placeholder,
  loading,
  minRows,
  disabled,
  options,
}) => {
  return (
    <Box>
      {options && (
        <Box
          sx={{
            overflowX: 'overlay',
            whiteSpace: 'nowrap',
            py: '5px',
            '&:hover::-webkit-scrollbar-thumb': {
              backgroundColor: '#bababa',
            },
            '&::-webkit-scrollbar': {
              height: '7px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#ffffff',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'transparent',
              borderRadius: '10px',
              position: 'absolute',
            },
          }}>
          {options.map((item) => (
            <Chip
              label={item}
              sx={{
                // display: 'inline-block',
                mx: 0.5,
              }}
              onClick={() => setText(item)}
              key={item}
            />
          ))}
        </Box>
      )}
      <Box
        sx={{
          // display: 'flex',
          // alignItems: 'center',
          // alignItems: 'start',
          // padding: '16px 5px 16px 16px',
          p: '12px',
          pt: options ? '7px' : '12px',
        }}>
        <TextField
          value={text}
          placeholder={placeholder + '...'}
          fullWidth
          autoFocus
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e) => {
            if (
              e.keyCode == 13
              // !e.shiftKey &&
              // !e.ctrlKey &&
              // !e.altKey &&
              // !e.metaKey &&
            ) {
              e.preventDefault()
              if (text.length > 0) onSend(e)
            }
          }}
          sx={{
            '& .MuiInputBase-root': {
              // padding: `14px ${isRTL() ? '7px' : '10px'} 14px ${
              //   !isRTL() ? '7px' : '10px'
              // } !important`,
              p: '5px',
            },
          }}
          inputProps={{
            sx: {
              fontSize: '16px',
              p: '10px',
            },
          }}
          multiline
          minRows={minRows}
          maxRows={6}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ height: 'auto', maxHeight: 'unset' }}>
                <IconButton
                  onClick={!loading ? onSend : undefined}
                  disabled={text.length === 0 || disabled}
                  variant="square"
                  color="primary">
                  {!loading ? (
                    <SendOutlined
                      sx={{
                        transform: isRTL()
                          ? 'rotate(180deg)'
                          : 'rotate(-45deg)',
                      }}
                    />
                  ) : (
                    <Loader
                      local
                      // containerStyle={{ margin: '5px 0 0 5px' }}
                      containerStyle={{
                        width: '24px',
                        height: '24px',
                      }}
                      spinnerProps={{ size: '24px' }}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  )
}

MessageField.defaultProps = {
  placeholder: 'Write a message',
  minRows: undefined,
}

export default MessageField
