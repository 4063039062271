import axios from '../axios'

const base = '/account'

export const loginUser = (payload) => {
  return axios.post(`${base}/login/`, payload)
}

export const getUserProfile = () => {
  return axios.get(`${base}/profile/`)
}

export const logoutUser = () => {
  return axios.post(`${base}/logout/`, {})
}
