export const ANSWER_QUALITY_CORRECT_AND_CLEAR = 1
export const ANSWER_QUALITY_WRONG = 2
export const ANSWER_QUALITY_CORRECT_AND_NOT_CLEAR = 3

export const ANSWER_QUALITY = [
  {
    title: 'Correct and clear',
    id: ANSWER_QUALITY_CORRECT_AND_CLEAR,
  },
  {
    title: 'Correct but not clear',
    id: ANSWER_QUALITY_CORRECT_AND_NOT_CLEAR,
  },
  {
    title: 'Wrong',
    id: ANSWER_QUALITY_WRONG,
  },
]

export const CONTENT_TYPE_TXT = 1
export const CONTENT_TYPE_DOC = 2
export const CONTENT_TYPE_XLS = 3
export const CONTENT_TYPE_PDF = 4
export const CONTENT_TYPE_JPG = 5
export const CONTENT_TYPE_AUDIO = 6

export const CONTENT_TYPE = [
  {
    title: 'Text',
    id: CONTENT_TYPE_TXT,
  },
  {
    title: 'Word Document',
    id: CONTENT_TYPE_DOC,
  },
  {
    title: 'XLS/XLSX Document',
    id: CONTENT_TYPE_XLS,
  },
  {
    title: 'PDF Document',
    id: CONTENT_TYPE_PDF,
  },
  {
    title: 'JPG Image',
    id: CONTENT_TYPE_JPG,
  },
  {
    title: 'Audio',
    id: CONTENT_TYPE_AUDIO,
  },
]

export const READY_STATUS_NEW = 1
export const READY_STATUS_IN_PROGRESS = 2
export const READY_STATUS_DONE = 3
export const READY_STATUS_FAILED = 4

export const READY_STATUS = [
  {
    title: 'New',
    id: READY_STATUS_NEW,
  },
  {
    title: 'In Progress',
    id: READY_STATUS_IN_PROGRESS,
  },
  {
    title: 'Done',
    id: READY_STATUS_DONE,
  },
  {
    title: 'Failed',
    id: READY_STATUS_FAILED,
  },
]

export const TALK_SIDE_USER = 1
export const TALK_SIDE_ASSISTANT = 2

export const ACCESS_LEVEL_PUBLIC = 1
export const ACCESS_LEVEL_PRIVATE = 2
export const ACCESS_LEVEL = [
  { title: 'Public', id: ACCESS_LEVEL_PUBLIC },
  { title: 'Private', id: ACCESS_LEVEL_PRIVATE },
]

export const CONDITION_TYPE_OR = 1
export const CONDITION_TYPE_AND = 2
export const CONDITION_TYPE_CUSTOM = 3
export const CONDITION_TYPE = [
  { title: 'Match at least one (OR)', id: CONDITION_TYPE_OR },
  { title: 'Match All (And)', id: CONDITION_TYPE_AND },
  { title: 'Custom condition', id: CONDITION_TYPE_CUSTOM },
]

export const TRANSITION_TO_FLOW = 1
export const TRANSITION_TO_PAGE = 2
export const TRANSITION_TO = [
  { title: 'Flow', id: TRANSITION_TO_FLOW },
  { title: 'Page', id: TRANSITION_TO_PAGE },
]

export const REQUEST_METHOD_GET = 1
export const REQUEST_METHOD_POST = 2
export const REQUEST_METHOD_PUT = 3
export const REQUEST_METHOD_DELETE = 4
export const REQUEST_METHOD_PATCH = 5
export const REQUEST_METHOD = [
  { title: 'Get', id: REQUEST_METHOD_GET },
  { title: 'Post', id: REQUEST_METHOD_POST },
  { title: 'Put', id: REQUEST_METHOD_PUT },
  { title: 'Delete', id: REQUEST_METHOD_DELETE },
  { title: 'Patch', id: REQUEST_METHOD_PATCH },
]

export const ROUT_OPERAND_TYPE_EQUAL = 1
export const ROUT_OPERAND_TYPE_NOT_EQUAL = 2
export const ROUT_OPERAND_TYPE_GREATER = 3
export const ROUT_OPERAND_TYPE_LESS = 4
export const ROUT_OPERAND_TYPE_LESS_OR_EQUAL = 5
export const ROUT_OPERAND_TYPE_GREATER_OR_EQUAL = 6
export const ROUT_OPERAND_TYPE_IN = 7
export const ROUT_OPERAND_TYPE_CONTAIN = 8
export const ROUT_OPERAND_TYPE = [
  { title: 'Equal To', id: ROUT_OPERAND_TYPE_EQUAL },
  { title: 'Not Equal to', id: ROUT_OPERAND_TYPE_NOT_EQUAL },
  { title: 'Greater than', id: ROUT_OPERAND_TYPE_GREATER },
  { title: 'Less than', id: ROUT_OPERAND_TYPE_LESS },
  { title: 'Less or equal to', id: ROUT_OPERAND_TYPE_LESS_OR_EQUAL },
  { title: 'greater or equal to', id: ROUT_OPERAND_TYPE_GREATER_OR_EQUAL },
  { title: 'In', id: ROUT_OPERAND_TYPE_IN },
  { title: 'Contain', id: ROUT_OPERAND_TYPE_CONTAIN },
]
