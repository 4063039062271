// import CurrencyFormat from "react-currency-format"
import store from '../../store/store'
const currencyFormat = (val, currency) => {
  const state = store.getState()
  if (!parseFloat(val)) return ''
  if (
    !currency &&
    !state.user.data?.businessObject?.setting?.currency_data?.short_form
  )
    return val
  return (
    (state.user.data?.businessObject?.setting?.currency_data?.short_form ||
      currency?.short_form ||
      '') +
    parseFloat(val)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  )
}

export default currencyFormat
