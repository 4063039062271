import { DeleteOutline } from '@mui/icons-material'
import ButtonWithConfirmation from './ButtonWithConfirmation'

const RemoveWithConfirmation = ({ onAccept, buttonProps, icon = false }) => {
  return (
    <ButtonWithConfirmation
      buttonText="Remove"
      dialogTitle="Remove this item"
      dialogDescription="Are you sure you want to remove this item?"
      onAccept={onAccept}
      buttonProps={{ color: 'error', ...buttonProps }}
      icon={icon}
      ButtonIcon={<DeleteOutline />}
    />
  )
}

export default RemoveWithConfirmation
