import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
  Typography,
} from '@mui/material'

const RadioGroup = (props) => {
  const {
    name,
    value,
    onChange,
    label,
    options,
    rules,
    helperText,
    error,
    asVal,
    row,
    disabled,
  } = props

  return (
    <FormControl disabled={disabled}>
      {label && (
        <FormLabel>
          {label} {rules.required ? '*' : ''}
        </FormLabel>
      )}
      <MuiRadioGroup row={row} name={name} value={value} onChange={onChange}>
        {options.map((item) => (
          <FormControlLabel
            value={
              typeof item === 'object'
                ? asVal
                  ? item[asVal]
                  : item.value
                : item
            }
            control={<Radio />}
            label={typeof item === 'object' ? item.title : item}
            key={typeof item === 'object' ? item.title : item}
          />
        ))}
      </MuiRadioGroup>
      {error && (
        <Typography
          color="error"
          variant="caption"
          sx={{ display: 'block', mt: 0.375, mx: 1.75 }}>
          {helperText[error.type]}
        </Typography>
      )}
    </FormControl>
  )
}
export default RadioGroup
