import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import {
  askQuestion,
  askQuestionInConversation,
  getConversation,
  getKnowledgeDetail,
  initConversation,
  submitAnswerRequest,
  submitReview,
} from '../api/lib/knowledge'
import { useTranslate } from '../locale/useTranslateHook'
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  ChevronLeft,
  ChevronRight,
  ErrorOutline,
  MoreVertOutlined,
  SentimentDissatisfiedOutlined,
  SentimentNeutralOutlined,
  SentimentSatisfiedOutlined,
  WarningRounded,
} from '@mui/icons-material'
import truncate from '../utils/truncate'
import LoadOrError from '../shared/components/global/LoadOrError'
import Chat from '../shared/components/chat/Index'
import {
  ANSWER_QUALITY,
  TALK_SIDE_ASSISTANT,
  TALK_SIDE_USER,
} from '../utils/consts'
import { current } from '@reduxjs/toolkit'
import FormBuilder from '../shared/components/formBuilder/FormBuilder'
import { useForm, useFormState } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import routeNames from '../router/routeNames'
import { colors } from '../styles/theme'
import { isMessageFromUser } from '../utils/contentTypes'

const ChatPage = (props) => {
  const params = useParams()
  const t = useTranslate()
  const navigate = useNavigate()
  const [notFound, setNotFound] = useState(false)
  const [knowledge, setKnowledge] = useState(null)
  const [conversation, setConversation] = useState(null)
  const [error, setError] = useState(null)
  const [chatError, setChatError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState('')
  const [typing, setTyping] = useState(false)
  const [messages, setMessages] = useState({})
  const [more, setMore] = useState(false)
  const [chatId, setChatId] = useState(undefined)

  const { handleSubmit, control, reset, watch } = useForm({
    mode: 'all',
    defaultValues: {
      phone_email: '',
      name: '',
    },
  })
  const { errors, isDirty, isValid } = useFormState({ control })
  const disabled = Boolean(Object.keys(errors).length) || !isValid || !isDirty

  const handleSend = (msg) => {
    if (msg.length === 0) return
    setChatError(false)
    setTyping(true)
    if (msg == text) setText('')
    setMessages((current) => ({
      ...current,
      [knowledge.id]: [
        ...(current[knowledge.id] || []),
        { side: TALK_SIDE_USER, text: msg, id: Date().toString() },
      ],
    }))
    askQuestionInConversation(params.talkId || chatId, { text: msg })
      .then((response) => {
        setTyping(false)
        setMessages((current) => ({
          ...current,
          [knowledge.id]: [
            ...(current[knowledge.id] || []),
            {
              side: TALK_SIDE_ASSISTANT,
              text: response.data.answer,
              ...response.data,
            },
          ],
        }))
      })
      .catch((e) => {
        setTyping(false)
        setChatError(true)
        setMessages((current) => ({
          ...current,
          [knowledge.id]: [
            ...(current[knowledge.id] || []),
            { side: TALK_SIDE_ASSISTANT, text: '', text: msg, error: true },
          ],
        }))
      })
  }

  const handleOrg = () => {
    setError(null)
    setLoading(true)
    if (params.talkId)
      Promise.all([
        getKnowledgeDetail(params.id),
        getConversation(params.talkId),
      ])
        .then(([knowledge, conversation]) => {
          setKnowledge(knowledge.data)
          setConversation(conversation.data)
          setMessages((current) => ({
            ...current,
            [knowledge.data.id]: [
              ...(current[knowledge.data.id] || []),
              ...conversation.data.history,
            ],
          }))
          setLoading(false)
        })
        .catch(() => {
          setError(t('Error occurred.'))
          setLoading(false)
        })
    else
      initConversation(params.id, {})
        .then((response) => {
          setKnowledge(response.data)
          navigate(routeNames.talkDetail.generate(params.id, response.data.id))
        })
        .catch(() => {
          setError(t('Error occurred.'))
          setLoading(false)
        })
  }
  const setAdminChatId = () => {
    setLoading(true)
    initConversation(props.knowledge.slug, {})
      .then((response) => {
        setLoading(false)
        localStorage.setItem(
          'chatIds',
          JSON.stringify({
            ...(localStorage.getItem('chatIds') &&
              JSON.parse(localStorage.getItem('chatIds'))),
            [props.knowledge.id]: response.data.id,
          })
        )
        setChatId(response.data.id)
        setMessages([])
      })
      .catch(() => {
        setError(t('Error occurred.'))
        setLoading(false)
      })
  }

  const location = useLocation()
  useEffect(() => {
    if (location.pathname.includes('/talk')) {
      if (params.id) handleOrg()
      else setNotFound(true)
    }
  }, [])
  useEffect(() => {
    setKnowledge(props.knowledge || null)
    if (props.admin && props.knowledge) {
      const cid =
        localStorage.getItem('chatIds') &&
        JSON.parse(localStorage.getItem('chatIds'))[props.knowledge.id]
      if (!cid) {
        setAdminChatId()
      } else {
        setChatId(cid)
      }
    }
  }, [props.knowledge])

  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedAnswer, setSelectedAnswer] = useState(null)
  const [feedbacks, setFeedbacks] = useState([])
  const menuOpen = Boolean(anchorEl)
  const openFeedbackMenu = (event, msg, index) => {
    setSelectedAnswer({ msg, index })
    setAnchorEl(event.currentTarget)
  }
  const closeFeedbackMenu = () => {
    setSelectedAnswer(null)
    setAnchorEl(null)
  }
  const handleFeedback = (feedback, answer) => {
    // const answer = selectedAnswer.msg
    submitReview(answer.id, {
      answer_quality: feedback,
    }).then((response) => {
      setFeedbacks((current) => [...current, answer.id])
      closeFeedbackMenu()
    })
  }

  const [dialogState, setDialogState] = useState(false)
  const [requests, setRequests] = useState([])
  const [reqLoading, setReqLoading] = useState(false)
  const openDialog = (msg, index) => {
    setSelectedAnswer({ msg, index })
    setDialogState(true)
  }
  const closeDialog = () => {
    setSelectedAnswer(null)
    setDialogState(null)
  }
  const handleRequest = (payload) => {
    setReqLoading(true)
    const answer = selectedAnswer.msg
    submitAnswerRequest(answer.id, payload)
      .then((response) => {
        setReqLoading(false)
        setRequests((current) => [...current, answer.id])
        closeDialog()
      })
      .catch(() => {
        setReqLoading(false)
      })
  }

  const getIcon = (id) => {
    if (id === 1)
      return (
        <SentimentSatisfiedOutlined sx={{ fontSize: '25px' }} color="primary" />
      )
    if (id === 2)
      return (
        <SentimentDissatisfiedOutlined
          sx={{ fontSize: '25px' }}
          color="primary"
        />
      )
    if (id === 3)
      return (
        <SentimentNeutralOutlined sx={{ fontSize: '25px' }} color="primary" />
      )
  }

  if (error || loading || !knowledge)
    return (
      <LoadOrError
        retry={props.admin ? setAdminChatId : handleOrg}
        error={error}
        loading={loading}
      />
    )

  return (
    <>
      <Container {...props.containerProps}>
        {props.admin && (
          <Button
            onClick={setAdminChatId}
            variant="contained"
            fullWidth
            size="small"
            sx={{ mb: 1, mt: '-10px' }}>
            {t('Restart conversation')}
          </Button>
        )}
        <Card variant="outlined" sx={{ height: 'calc(100% - 30px)' }}>
          <CardContent
            variant="noExtraPadding"
            sx={{
              height: '100%',
              padding: '0px !important',
              whiteSpace: 'pre-wrap',
            }}>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <ButtonBase
                sx={{ width: '100%', textAlign: 'initial' }}
                onClick={() => setMore(!more)}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '16px',
                    width: '100%',
                  }}>
                  {/* <Avatar sx={{ mr: 1.5 }} /> */}
                  <img
                    src={knowledge.icon}
                    width={75}
                    height={75}
                    style={{ objectFit: 'contain' }}
                  />
                  <Box sx={{ flexGrow: 1, ml: 1.5 }}>
                    <Typography fontWeight="bold">{knowledge.title}</Typography>
                    {/* <Typography variant="body2">
                      {more
                        ? knowledge.description
                        : truncate(knowledge.description, 150)}
                    </Typography> */}
                    {/* <Collapse in={more} timeout="auto" unmountOnExit>
                      <Typography variant="body2">
                        {knowledge.description}
                      </Typography>
                    </Collapse> */}
                  </Box>
                  {knowledge.description &&
                    knowledge.description.length > 150 && (
                      <IconButton sx={{ mt: '18px' }}>
                        {more ? (
                          <ChevronLeft sx={{ transform: 'rotate(90deg)' }} />
                        ) : (
                          <ChevronRight sx={{ transform: 'rotate(90deg)' }} />
                        )}
                      </IconButton>
                    )}
                </Box>
              </ButtonBase>
              <Chat
                onSend={() => handleSend(text)}
                text={text}
                setText={setText}
                messages={messages[knowledge.id] || []}
                typing={typing}
                placeholder={t('Ask your question')}
                disabled={typing}
                // Starter={() => (
                //   <Card
                //     variant="outlined"
                //     sx={{
                //       p: 2,
                //       backgroundColor: colors.grey[100],
                //       mx: 'auto',
                //       minWidth: { xs: '100%', md: '70%', lg: '60%', xl: '50%' },
                //     }}>
                //     <Typography variant="body2">
                //       {more
                //         ? knowledge.description
                //         : truncate(knowledge.description, 150)}
                //     </Typography>
                //     <List dense sx={{ listStyle: 'disc', pl: 3 }}>
                //       <ListItem
                //         sx={{
                //           display: 'list-item',
                //         }}>
                //         <ListItemText
                //           primary="پیش خرید ملک"
                //           sx={{ position: 'relative', left: '-15px' }}
                //         />
                //       </ListItem>
                //       <ListItem
                //         sx={{
                //           display: 'list-item',
                //         }}>
                //         <ListItemText
                //           primary="نفقه"
                //           sx={{ position: 'relative', left: '-15px' }}
                //         />
                //       </ListItem>
                //     </List>
                //   </Card>
                // )}
                Append={(msg, index) =>
                  isMessageFromUser(msg.side) ? undefined : msg.has_answer ? (
                    feedbacks.includes(msg.id) ? (
                      <Typography variant="small">
                        {t('Thanks for feedback!')}
                      </Typography>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* <Button
                          onClick={(e) => openFeedbackMenu(e, msg, index)}
                          aria-controls={
                            menuOpen ? `answer-feedback` : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={menuOpen ? 'true' : undefined}>
                          <Typography variant="small">
                            {t('Feedback')}
                          </Typography>
                        </Button> */}
                        {ANSWER_QUALITY.map((item) => (
                          <Tooltip title={t(item.title)} key={item.title}>
                            <IconButton
                              size="small"
                              variant="square"
                              // onClick={() => handleFeedback(item.id, msg)}
                            >
                              {getIcon(item.id)}
                            </IconButton>
                          </Tooltip>
                        ))}
                      </Box>
                    )
                  ) : requests.includes(msg.id) ? (
                    <Typography>
                      {t(
                        'Thanks for submitting your request, we will contact you.'
                      )}
                    </Typography>
                  ) : msg.error ? (
                    <>
                      <Typography
                        color="error"
                        sx={{ display: 'flex', alignItems: 'center' }}>
                        <ErrorOutline color="error" sx={{ mr: 1 }} />
                        {t('Error occurred.')}
                      </Typography>
                      {index === messages[knowledge.id].length - 1 && (
                        <Button
                          variant="text"
                          color="error"
                          sx={{ ml: 3 }}
                          onClick={() => {
                            setMessages((current) => {
                              const data = current[knowledge.id]
                              data.splice(index - 1, 2)
                              return {
                                ...current,
                                [knowledge.id]: data,
                              }
                            })
                            handleSend(msg.question)
                          }}>
                          {t('Try again.')}
                        </Button>
                      )}
                    </>
                  ) : msg.has_answer === undefined ? (
                    <></>
                  ) : (
                    <>
                      <Typography>
                        {t(
                          'No answer found, use this button to submit answer request so we can contact you.'
                        )}
                      </Typography>
                      <Button
                        onClick={() => openDialog(msg, index)}
                        variant="contained"
                        sx={{ textAlign: 'initial', mt: 1 }}>
                        {t('Request for answer')}
                      </Button>
                    </>
                  )
                }
              />
              {/* <Menu
                anchorEl={anchorEl}
                id="answer-feedback"
                open={menuOpen}
                onClose={closeFeedbackMenu}
                onClick={closeFeedbackMenu}
                transformOrigin={{
                  horizontal: 'right',
                  vertical: 'top',
                }}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    boxShadow:
                      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                  },
                }}>
                {ANSWER_QUALITY.map((item) => (
                  <MenuItem onClick={() => handleFeedback(item.id)}>
                    <Typography variant="small">{t(item.title)}</Typography>
                  </MenuItem>
                ))}
              </Menu> */}
              <Dialog open={dialogState} onClose={closeDialog}>
                <DialogTitle>{t('Request for answer')}</DialogTitle>
                <DialogContent>
                  <DialogContentText mb={2}>
                    <Typography>
                      {t(
                        'Please enter your name and phone number or email address.'
                      )}
                    </Typography>
                  </DialogContentText>

                  <FormBuilder
                    formName="requestAnswer"
                    control={control}
                    t={t}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeDialog}>{t('Cancel')}</Button>
                  <LoadingButton
                    onClick={handleSubmit(handleRequest)}
                    disabled={disabled}
                    loading={reqLoading}>
                    {t('Submit')}
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            </Box>
          </CardContent>
        </Card>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '30px',
          }}>
          <Typography variant="caption">{t('Rostam AI platform.')}</Typography>
          <Typography variant="caption">
            {t('Lean solution with LeanB.')}{' '}
            <a href="https://leanb.net/" target="_blank">
              {t('LeanB')}
            </a>
          </Typography>
        </Box>
      </Container>
    </>
  )
}

ChatPage.defaultProps = {
  containerProps: {
    sx: { height: '100vh', pb: 0, pt: 2 },
    maxWidth: 'md',
  },
}

export default ChatPage
