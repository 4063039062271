import { Controller, useController } from 'react-hook-form'
import RichTextEditor from './RichTextEditor'

const RichTextEditorWithValidation = (props) => {
  const { name, control, rules, ...editorProps } = props

  const {
    field: { onChange, onBlur, ref, value },
    fieldState,
  } = useController({
    name,
    control,
    rules,
  })

  return (
    <RichTextEditor
      rules={rules}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      handleRef={ref}
      error={fieldState.error}
      {...editorProps}
    />
  )
}

export default RichTextEditorWithValidation
