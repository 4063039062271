// routing base_url
const baseUrl = process.env.BASE_URL || ''

const routeNames = {
  // landing: `${baseUrl}/`,
  // edadLanding: `${baseUrl}/la`,

  // homepage: `${baseUrl}/home`,
  homepage: `${baseUrl}/dashboard/knowledges`,
  knowledgesList: `${baseUrl}/dashboard/knowledges`,
  knowledgeDetail: {
    pattern: `${baseUrl}/dashboard/knowledges/:id`,
    generate: (id) => `${baseUrl}/dashboard/knowledges/${id}`,
  },
  agentsList: `${baseUrl}/dashboard/agents`,
  // agentDetail: {
  //   pattern: `${baseUrl}/dashboard/agents/:id`,
  //   generate: (id) => `${baseUrl}/dashboard/agents/${id}`,
  // },
  agent: {
    pattern: `${baseUrl}/dashboard/agents/:id`,
    // children
    details: {
      pattern: ``,
      generate: (id) => `${baseUrl}/dashboard/agents/${id}`,
    },
    intents: {
      pattern: `intents`,
      generate: (id) => `${baseUrl}/dashboard/agents/${id}/intents`,
    },
    intentDetail: {
      pattern: `intents/:intentId`,
      generate: (id, intentId) =>
        `${baseUrl}/dashboard/agents/${id}/intents/${intentId}`,
    },
    entityTypes: {
      pattern: `entity-types`,
      generate: (id) => `${baseUrl}/dashboard/agents/${id}/entity-types`,
    },
    entityTypeDetail: {
      pattern: `entity-types/:entityTypeId`,
      generate: (id, entityTypeId) =>
        `${baseUrl}/dashboard/agents/${id}/entity-types/${entityTypeId}`,
    },
    webhooks: {
      pattern: `webhooks`,
      generate: (id) => `${baseUrl}/dashboard/agents/${id}/webhooks`,
    },
    webhookDetail: {
      pattern: `webhook/:webhookId`,
      generate: (id, webhookId) =>
        `${baseUrl}/dashboard/agents/${id}/webhook/${webhookId}`,
    },
  },
  contentsList: {
    pattern: `${baseUrl}/dashboard/knowledges/:id/contents`,
    generate: (id) => `${baseUrl}/dashboard/knowledges/${id}/contents`,
  },
  exportQuestions: {
    pattern: `${baseUrl}/dashboard/knowledges/:id/export-questions`,
    generate: (id) => `${baseUrl}/dashboard/knowledges/${id}/export-questions`,
  },
  askedQuestions: {
    pattern: `${baseUrl}/dashboard/knowledges/:id/asked-questions`,
    generate: (id) => `${baseUrl}/dashboard/knowledges/${id}/asked-questions`,
  },
  usersComments: {
    pattern: `${baseUrl}/dashboard/knowledges/:id/users-comments`,
    generate: (id) => `${baseUrl}/dashboard/knowledges/${id}/users-comments`,
  },
  settings: {
    pattern: `${baseUrl}/dashboard/knowledges/:id/settings`,
    generate: (id) => `${baseUrl}/dashboard/knowledges/${id}/settings`,
  },
  contentDetail: {
    pattern: `${baseUrl}/dashboard/knowledges/:id/content/:content`,
    generate: (id, content) =>
      `${baseUrl}/dashboard/knowledges/${id}/content/${content}`,
  },

  chat: {
    pattern: `${baseUrl}/chat/:id`,
    generate: (id) => `${baseUrl}/chat/${id}`,
  },
  talk: {
    pattern: `${baseUrl}/talk/:id/`,
    generate: (id) => `${baseUrl}/talk/${id}`,
  },
  talkDetail: {
    pattern: `${baseUrl}/talk/:id/:talkId`,
    generate: (id, talkId) => `${baseUrl}/talk/${id}/${talkId}`,
  },

  login: `${baseUrl}/dashboard/login`,
  logout: `${baseUrl}/dashboard/logout`,
}

export default routeNames
