import Cookies from 'js-cookie'
import isDashboard from './isDashboard'

export const setAccessToken = (accessToken) =>
  Cookies.set('access_token', accessToken, { expires: 30 })
export const setDevId = (devId) => Cookies.set('dev_id', devId, { expires: 30 })
export const getAccessToken = (cookies) =>
  cookies ? cookies.access_token : Cookies.get('access_token')
export const getDevId = () => Cookies.get('dev_id')
export const removeAccessToken = () => Cookies.remove('access_token')
export const removeDevId = () => Cookies.remove('dev_id')
export const isAuthenticated = (cookies) => !!getAccessToken(cookies)
export const selectedLang = (cookies) =>
  (cookies ? cookies.language : Cookies.get('language')) ||
  (isDashboard()
    ? process.env.REACT_APP_DEFAULT_LANG
    : process.env.NEXT_PUBLIC_DEFAULT_LANG)
export const setSelectedLang = (code) => Cookies.set('language', code)

export default isAuthenticated
