import { createKeyword, getKeywords } from '../../api/lib/basedata'
import {
  ACCESS_LEVEL,
  CONDITION_TYPE,
  REQUEST_METHOD,
  ROUT_OPERAND_TYPE,
  TRANSITION_TO,
} from '../../utils/consts'
import {
  isConditionTypeCustom,
  isTransitionToPage,
} from '../../utils/contentTypes'

export const knowledge = ({ t }, _) => {
  const required = t('This field is required.')

  return new Promise((resolve) => {
    resolve([
      {
        label: t('Title'),
        name: 'title',
        type: 0,
        placeholder: t('Title'),
        rules: { required: true },
        helperText: { required },
        margin: 'normal',
      },
      {
        label: t('Slug'),
        name: 'slug',
        type: 0,
        placeholder: t('Slug'),
        rules: { required: true },
        helperText: {
          required,
          helper: t('Chat address. Example: hello-world'),
        },
        margin: 'normal',
      },
      {
        label: t('Logo'),
        name: 'icon',
        type: 4,
        placeholder: t('Logo'),
        rules: {},
        helperText: {},
        margin: 'normal',
        multiple: false,
        accept: 'image/png, image/gif, image/jpeg',
      },
      {
        label: t('Description'),
        name: 'description',
        type: 3,
        placeholder: t('Description'),
        rules: { required: true },
        helperText: { required },
        margin: 'normal',
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Language'),
        name: 'language',
        type: 13,
        placeholder: t('Language'),
        rules: { required: true },
        helperText: { required },
        margin: 'normal',
        itemsByBasedata: 'languages',
      },
    ])
  })
}

export const requestAnswer = ({ t }, _) => {
  const required = t('This field is required.')
  return new Promise((resolve) => {
    resolve([
      {
        label: t('Name'),
        name: 'name',
        type: 0,
        placeholder: t('Name'),
        rules: { required: true },
        helperText: { required },
        margin: 'normal',
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Phone number or email'),
        name: 'phone_email',
        type: 0,
        placeholder: t('Phone number or email'),
        rules: { required: true },
        helperText: { required },
        margin: 'normal',
        gridItemProps: {
          md: 12,
        },
      },
    ])
  })
}

export const content = ({ t }, _) => {
  const required = t('This field is required.')
  return new Promise((resolve) => {
    resolve([
      {
        label: t('Title'),
        name: 'title',
        type: 0,
        placeholder: t('Title'),
        rules: { required: true },
        helperText: { required },
        margin: 'normal',
        gridItemProps: {
          md: 8,
        },
      },
      {
        type: 'title',
        text: t(
          'You can decide to use text field or file field to import data, only one of them is usable for a content and only one of them is required. You can select these file types and only one file: Word Document, XLS/XLSX Document, PDF Document, JPG Image or Audio.'
        ),
        typographyProps: { variant: 'body1' },
        gridItemProps: {
          my: 2,
          md: 12,
        },
      },
      {
        label: t('File'),
        name: 'file',
        type: 4,
        placeholder: t('File'),
        rules: (values) => ({
          required: !values.text || values.text.length === 0,
        }),
        helperText: { required },
        margin: 'normal',
        multiple: false,
        accept: '',
        renderCondition: (values) => !values.text || values.text.length === 0,
      },
      {
        label: t('Text'),
        name: 'text',
        type: 3,
        placeholder: t('Text'),
        rules: (values) => ({
          required: !values.file || values.file.length === 0,
        }),
        helperText: { required },
        margin: 'normal',
        renderCondition: (values) => !values.file || values.file.length === 0,
        gridItemProps: {
          md: 12,
        },
      },
    ])
  })
}

export const agent = ({ t, agentRoles }, _) => {
  const required = t('This field is required.')
  return new Promise((resolve) => {
    resolve([
      {
        type: 'title',
        text: t('General information'),
        typographyProps: { variant: 'h4' },
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Title'),
        name: 'title',
        type: 0,
        placeholder: t('Title'),
        rules: { required: true },
        helperText: { required },
        margin: 'normal',
      },
      {
        label: t('Time zone'),
        name: 'timezone',
        type: 13,
        placeholder: t('Time zone'),
        rules: {},
        helperText: { required },
        margin: 'normal',
        itemsByBasedata: 'timezones',
      },
      {
        label: t('Agent role'),
        name: 'agent_role',
        type: 13,
        placeholder: t('Agent role'),
        rules: {},
        helperText: { required },
        margin: 'normal',
        items: agentRoles,
      },
      {
        label: t('Keywords'),
        name: 'keywords',
        type: 19,
        placeholder: t('Keywords'),
        rules: { required: true },
        helperText: { required },
        margin: 'normal',
        multiple: true,
        endpoint: getKeywords,
        createEndpoint: createKeyword,
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Description'),
        name: 'description',
        type: 3,
        placeholder: t('Description'),
        rules: {},
        helperText: { required },
        margin: 'normal',
        gridItemProps: {
          md: 12,
        },
      },
      // {
      //   label: t('Avatar'),
      //   name: 'avatar',
      //   type: 4,
      //   placeholder: t('Avatar'),
      //   rules: {},
      //   helperText: { required },
      //   margin: 'normal',
      //   multiple: false,
      //   accept: 'image/png, image/jpeg',
      // },

      {
        type: 'title',
        text: t('ML configuration'),
        typographyProps: { variant: 'h4' },
        gridItemProps: {
          mt: 2,
          md: 12,
        },
      },
      {
        label: t('Prompt for generative model'),
        name: 'prompt',
        type: 3,
        placeholder: t('Prompt for generative model'),
        rules: {},
        helperText: { required },
        margin: 'normal',
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Banned phrases'),
        name: 'banned_phrases',
        type: 19,
        placeholder: t('Banned phrases'),
        rules: { required: true },
        helperText: { required },
        margin: 'normal',
        multiple: true,
        endpoint: getKeywords,
        createEndpoint: createKeyword,
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Write / Speech languages'),
        name: 'speech_languages',
        type: 13,
        placeholder: t('Write / Speech languages'),
        rules: { required: true },
        helperText: { required, helper: t('Consider a translation usecase.') },
        margin: 'normal',
        itemsByBasedata: 'languages',
        multiple: true,
      },
      {
        label: t('Read / Listen Languages'),
        name: 'languages',
        type: 13,
        placeholder: t('Read / Listen Languages'),
        rules: { required: true },
        helperText: { required, helper: t('Consider a translation usecase.') },
        margin: 'normal',
        itemsByBasedata: 'languages',
        multiple: true,
      },
      { type: 'empty' },
      {
        label: t('Use speech to text'),
        name: 'speech_to_text',
        type: 18,
      },

      {
        type: 'title',
        text: t('Security configuration'),
        typographyProps: { variant: 'h4' },
        gridItemProps: {
          mt: 3,
          md: 12,
        },
      },
      {
        label: t('Access level'),
        name: 'access_level',
        type: 5,
        placeholder: t('Access level'),
        rules: { required: true },
        helperText: { required },
        margin: 'normal',
        items: ACCESS_LEVEL,
        asVal: 'title',
      },
      { type: 'empty' },
      { type: 'empty' },
      {
        label: t('Need authentication?'),
        name: 'need_authentication',
        type: 18,
      },
    ])
  })
}

export const intent = ({ t }, _) => {
  const required = t('This field is required.')
  return new Promise((resolve) => {
    resolve([
      {
        label: t('Title'),
        name: 'title',
        type: 0,
        placeholder: t('Title'),
        rules: { required: true },
        helperText: { required },
        margin: 'normal',
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Description'),
        name: 'description',
        type: 3,
        placeholder: t('Description'),
        rules: {},
        helperText: { required },
        margin: 'normal',
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Training phrases'),
        name: 'sentences',
        type: 19,
        placeholder: t('Training phrases'),
        rules: { required: true },
        helperText: { required },
        margin: 'normal',
        multiple: true,
        endpoint: getKeywords,
        createEndpoint: createKeyword,
        gridItemProps: {
          md: 12,
        },
      },
    ])
  })
}

export const entityType = ({ t }, _) => {
  const required = t('This field is required.')
  return new Promise((resolve) => {
    resolve([
      {
        label: t('Title'),
        name: 'title',
        type: 0,
        placeholder: t('Title'),
        rules: { required: true },
        helperText: { required },
        margin: 'normal',
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Name'),
        name: 'name',
        type: 0,
        placeholder: t('Name'),
        rules: { required: true },
        helperText: { required },
        margin: 'normal',
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Description'),
        name: 'description',
        type: 3,
        placeholder: t('Description'),
        rules: {},
        helperText: { required },
        margin: 'normal',
        gridItemProps: {
          md: 12,
        },
      },
      {
        type: 'title',
        text: t('Training phrases'),
        typographyProps: { variant: 'h4' },
        gridItemProps: {
          md: 12,
          mb: 1,
        },
      },
      {
        type: 12,
        name: 'phrases',
        rules: { minLength: 1 },
        helperText: {
          minLength: t('You need to have at least 1 training phrase.'),
        },
        keyWord: 'phrases',
        gridItemProps: {
          md: 12,
        },
        fields: (index) => [
          {
            label: t('Sentence'),
            name: `sentence`,
            type: 0,
            placeholder: t('Sentence'),
            rules: { required: true },
            helperText: { required },
            margin: 'normal',
            defaultValue: '',
            index,
            gridItemProps: {
              md: 6,
            },
          },
          {
            label: t('Synonym'),
            name: `synonym`,
            type: 0,
            placeholder: t('Synonym'),
            rules: { required: true },
            helperText: { required },
            margin: 'normal',
            defaultValue: '',
            index,
            gridItemProps: {
              md: 6,
            },
          },
          {
            label: t('Sample text'),
            name: 'sample_text',
            type: 3,
            placeholder: t('Sample text'),
            rules: { required: true },
            helperText: { required },
            margin: 'normal',
            defaultValue: '',
            index,
            gridItemProps: {
              md: 12,
            },
          },
          {
            type: 'empty',
            remove: () => true,
            index,
            gridItemProps: {
              md: 12,
              display: 'flex',
              // justifyContent: 'end',
              alignItems: 'center',
            },
            buttonProps: {
              fullWidth: false,
              size: 'small',
            },
          },
        ],
      },
      {
        label: t('Exclude words'),
        name: 'exclude_words',
        type: 19,
        placeholder: t('Exclude words'),
        rules: {},
        helperText: {},
        margin: 'normal',
        multiple: true,
        endpoint: getKeywords,
        createEndpoint: createKeyword,
        gridItemProps: {
          md: 12,
        },
      },
    ])
  })
}

export const webhook = ({ t, entityTypes }) => {
  const required = t('This field is required.')
  return new Promise((resolve) => {
    resolve([
      {
        type: 'title',
        text: t('General information'),
        typographyProps: { variant: 'h4' },
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Title'),
        name: 'title',
        type: 0,
        placeholder: t('Title'),
        rules: { required: true },
        helperText: { required },
        margin: 'normal',
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('URL'),
        name: 'url',
        type: 6,
        placeholder: t('URL'),
        rules: { required: true },
        helperText: { required },
        margin: 'normal',
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Method'),
        name: 'method',
        type: 5,
        placeholder: t('Method'),
        rules: { required: true },
        helperText: { required },
        margin: 'normal',
        items: REQUEST_METHOD,
      },
      {
        label: t('Body JSON'),
        name: 'body_json',
        type: 3,
        placeholder: t('Body JSON'),
        rules: {},
        helperText: { required },
        margin: 'normal',
        gridItemProps: {
          md: 12,
        },
      },
      // {
      //   type: 'title',
      //   text: t('Response configuration'),
      //   typographyProps: { variant: 'h4' },
      //   gridItemProps: {
      //     md: 12,
      //     mb: 1,
      //   },
      // },
      // {
      //   type: 12,
      //   name: 'parameters',
      //   rules: { minLength: 1 },
      //   helperText: {
      //     minLength: t('You need to have at least 1 configuration.'),
      //   },
      //   keyWord: 'property',
      //   gridItemProps: {
      //     md: 12,
      //   },
      //   fields: (index) => [
      //     {
      //       label: t('Key'),
      //       name: `key`,
      //       type: 0,
      //       placeholder: t('Key'),
      //       rules: { required: true },
      //       helperText: { required },
      //       margin: 'normal',
      //       defaultValue: '',
      //       index,
      //     },
      //     {
      //       label: t('Parameter'),
      //       name: 'parameter',
      //       type: 13,
      //       placeholder: t('Parameter'),
      //       rules: { required: true },
      //       helperText: { required, helper: t('Entity type item') },
      //       margin: 'normal',
      //       items: entityTypes,
      //       defaultValue: null,
      //     },
      //     {
      //       type: 'empty',
      //       remove: () => true,
      //       index,
      //       gridItemProps: {
      //         md: 4,
      //         display: 'flex',
      //         // justifyContent: 'end',
      //         alignItems: 'center',
      //       },
      //       buttonProps: {
      //         fullWidth: false,
      //         size: 'small',
      //       },
      //     },
      //   ],
      // },
      // {
      //   type: 'title',
      //   text: t('Authentication configuration'),
      //   typographyProps: { variant: 'h4' },
      //   gridItemProps: {
      //     md: 12,
      //   },
      // },
      // {
      //   label: t('Username'),
      //   name: 'authentication_username',
      //   type: 0,
      //   placeholder: t('Username'),
      //   rules: {},
      //   helperText: { required },
      //   margin: 'normal',
      // },
      // {
      //   label: t('Password'),
      //   name: 'authentication_password',
      //   type: 0,
      //   placeholder: t('Password'),
      //   rules: {},
      //   helperText: { required },
      //   margin: 'normal',
      // },
      // {
      //   label: t('CA cert file'),
      //   name: 'authentication_ca_files',
      //   type: 4,
      //   placeholder: t('CA cert file'),
      //   rules: {},
      //   helperText: { required },
      //   margin: 'normal',
      //   multiple: false,
      //   accept: '.crt',
      // },
    ])
  })
}

export const flow = ({ t, intents, knowledges }, _) => {
  const required = t('This field is required.')
  return new Promise((resolve) => {
    resolve([
      {
        label: t('Title'),
        name: 'title',
        type: 0,
        placeholder: t('Title'),
        rules: { required: true },
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Prompt'),
        name: 'prompt',
        type: 3,
        placeholder: t('Prompt'),
        rules: {},
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Generation rules'),
        name: 'generation_rules',
        type: 3,
        placeholder: t('Generation rules'),
        rules: {},
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Generation styles'),
        name: 'generation_styles',
        type: 3,
        placeholder: t('Generation styles'),
        rules: {},
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Description'),
        name: 'description',
        type: 3,
        placeholder: t('Description'),
        rules: {},
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Start intent'),
        name: 'start_intent',
        type: 13,
        placeholder: t('Start intent'),
        rules: { required: true },
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
        },
        items: intents,
      },
      {
        label: t('Reference knowledge'),
        name: 'reference_knowledge',
        type: 13,
        placeholder: t('Reference knowledge'),
        rules: {},
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
        },
        items: knowledges.results,
      },
      {
        label: t('Speech enabled'),
        name: 'is_speech_enable',
        type: 18,
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Use user history'),
        name: 'use_user_history',
        type: 18,
        gridItemProps: {
          md: 12,
        },
      },
    ])
  })
}

export const page = ({ t, webhooks, contents, intents }, _) => {
  const required = t('This field is required.')
  return new Promise((resolve) => {
    resolve([
      {
        label: t('Title'),
        name: 'title',
        type: 0,
        placeholder: t('Title'),
        rules: { required: true },
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Start intent'),
        name: 'start_intent',
        type: 13,
        placeholder: t('Start intent'),
        rules: { required: true },
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
        },
        items: intents,
      },
      {
        label: t('Description'),
        name: 'description',
        type: 3,
        placeholder: t('Description'),
        rules: {},
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Knowledge contents'),
        name: 'knowledge_contents',
        type: 13,
        placeholder: t('Knowledge contents'),
        multiple: true,
        rules: {},
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
        },
        items: contents,
      },
      {
        label: t('Webhook settings'),
        name: 'webhook_settings',
        type: 13,
        placeholder: t('Webhook settings'),
        multiple: true,
        rules: {},
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
        },
        items: webhooks,
      },
      {
        label: t('Agent responses'),
        name: 'agent_responses',
        type: 19,
        placeholder: t('Agent responses'),
        rules: {},
        helperText: { required },
        margin: 'normal',
        multiple: true,
        endpoint: getKeywords,
        createEndpoint: createKeyword,
        gridItemProps: {
          md: 12,
        },
      },
      // {
      //   label: t('Generators'),
      //   name: 'generators',
      //   type: 13,
      //   placeholder: t('Generators'),
      //   rules: { required: true },
      //   helperText: { required },
      //   margin: 'normal',
      //   items: [],
      // },
      {
        label: t('Use knowledge response'),
        name: 'use_knowledge_for_response',
        type: 18,
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Use AI response'),
        name: 'use_ai_for_response',
        type: 18,
        gridItemProps: {
          md: 12,
        },
      },
    ])
  })
}

export const route = (
  { t, webhooks, contents, intents, pages, flows, entityTypes },
  _
) => {
  const required = t('This field is required.')
  return new Promise((resolve) => {
    resolve([
      {
        label: t('Title'),
        name: 'title',
        type: 0,
        placeholder: t('Title'),
        rules: { required: true },
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Intent'),
        name: 'intent',
        type: 13,
        placeholder: t('Intent'),
        rules: { required: true },
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
        },
        items: intents,
      },
      {
        label: t('Description'),
        name: 'description',
        type: 3,
        placeholder: t('Description'),
        rules: {},
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Knowledge contents'),
        name: 'knowledge_contents',
        type: 13,
        placeholder: t('Knowledge contents'),
        multiple: true,
        rules: {},
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
        },
        items: contents,
      },
      {
        label: t('Webhook'),
        name: 'webhook',
        type: 13,
        placeholder: t('Webhook'),
        rules: {},
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
        },
        items: webhooks,
      },
      {
        label: t('Agent responses'),
        name: 'agent_responses',
        type: 19,
        placeholder: t('Agent responses'),
        rules: {},
        helperText: { required },
        margin: 'dense',
        multiple: true,
        endpoint: getKeywords,
        createEndpoint: createKeyword,
        gridItemProps: {
          md: 12,
        },
      },
      // {
      //   label: t('Generators'),
      //   name: 'generators',
      //   type: 13,
      //   placeholder: t('Generators'),
      //   rules: { required: true },
      //   helperText: { required },
      //   margin: 'normal',
      //   items: [],
      // },
      {
        label: t('Transition to'),
        name: 'transition_to',
        type: 5,
        placeholder: t('Transition to'),
        rules: { required: true },
        helperText: { required },
        margin: 'dense',
        items: TRANSITION_TO,
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Transition to page'),
        name: 'transition_to_page',
        type: 13,
        placeholder: t('Transition to page'),
        // rules: { required: true },
        rules: (values) => ({
          required:
            values.transition_to && isTransitionToPage(values.transition_to.id),
        }),
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
        },
        items: pages,
        renderCondition: (values) => {
          const temp =
            values.transition_to && isTransitionToPage(values.transition_to.id)
          return temp
        },
      },
      {
        label: t('Transition to flow'),
        name: 'transition_to_flow',
        type: 13,
        placeholder: t('Transition to flow'),
        // rules: { required: true },
        rules: (values) => ({
          required:
            values.transition_to &&
            !isTransitionToPage(values.transition_to.id),
        }),
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
        },
        items: flows,
        renderCondition: (values) => {
          const temp =
            values.transition_to && !isTransitionToPage(values.transition_to.id)
          return temp
        },
      },
      {
        label: t('Condition type'),
        name: 'condition_type',
        type: 5,
        placeholder: t('Condition type'),
        rules: { required: true },
        helperText: { required },
        margin: 'dense',
        items: CONDITION_TYPE,
        gridItemProps: {
          md: 12,
        },
      },
      {
        type: 'title',
        text: t('Conditions'),
        typographyProps: { variant: 'h4' },
        gridItemProps: {
          md: 12,
          mb: 2,
        },
      },
      {
        type: 12,
        name: 'conditions',
        // rules: (values) => ({
        //   required:
        //     values.condition_type &&
        //     !isConditionTypeCustom(values.condition_type.id),
        // }),
        rules: {
          required: true,
        },
        // rules: {
        //   minLength: 1,
        // },
        helperText: { minLength: t('You need to have at least 1 condition.') },
        keyWord: '',
        gridItemProps: {
          md: 12,
        },
        renderCondition: (values) => {
          const temp =
            values.condition_type &&
            !isConditionTypeCustom(values.condition_type.id)
          return temp
        },
        fields: (index) => [
          {
            label: t('Parameter'),
            name: 'parameter',
            type: 13,
            placeholder: t('Parameter'),
            rules: { required: true },
            helperText: { required, helper: t('Entity type item') },
            margin: 'dense',
            items: entityTypes,
            defaultValue: null,
            gridItemProps: {
              md: 12,
            },
          },
          {
            label: t('Operand'),
            name: 'operand',
            type: 5,
            placeholder: t('Operand'),
            rules: { required: true },
            helperText: { required },
            margin: 'dense',
            items: ROUT_OPERAND_TYPE,
            defaultValue: null,
            index,
            gridItemProps: {
              md: 12,
            },
          },
          {
            label: t('Value'),
            name: 'value',
            type: 0,
            placeholder: t('Value'),
            rules: { required: true },
            helperText: { required },
            margin: 'dense',
            defaultValue: '',
            index,
            gridItemProps: {
              md: 12,
            },
          },
          {
            type: 'empty',
            remove: () => true,
            index,
            gridItemProps: {
              md: 12,
              display: 'flex',
              // justifyContent: 'end',
              alignItems: 'center',
            },
            buttonProps: {
              fullWidth: false,
              size: 'small',
            },
          },
        ],
      },
      {
        label: t('Custom expression'),
        name: 'custom_expression',
        type: 0,
        placeholder: t('Custom expression'),
        // rules: (values) => ({
        //   required:
        //     values.condition_type &&
        //     isConditionTypeCustom(values.condition_type.id),
        // }),
        rules: {
          required: true,
        },
        helperText: { required },
        margin: 'dense',
        gridItemProps: {
          md: 12,
          mt: '-20px',
        },
        dynamicKey: true,
        renderCondition: (values) => {
          const temp =
            values.condition_type &&
            isConditionTypeCustom(values.condition_type.id)
          return temp
        },
      },
      {
        label: t('Use knowledge response'),
        name: 'use_knowledge_for_response',
        type: 18,
        gridItemProps: {
          md: 12,
        },
      },
      {
        label: t('Use AI response'),
        name: 'use_ai_for_response',
        type: 18,
        gridItemProps: {
          md: 12,
        },
      },
    ])
  })
}
