import { createContext, useContext } from 'react'

const TranslationContext = createContext()

export function TranslationWrapper({ translations, children }) {
  return (
    <TranslationContext.Provider value={translations || []}>
      {children}
    </TranslationContext.Provider>
  )
}

export function useTranslations() {
  return useContext(TranslationContext)
}
