import { Slider } from '@mui/material'
import { Controller } from 'react-hook-form'

const SliderInputWithValidation = (props) => {
  const { name, control, rules, ...sliderInputProps } = props

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field,
        fieldState: { invalid, isTouched, isDirty, error },
      }) => <Slider {...field} {...sliderInputProps} />}
    />
  )
}

SliderInputWithValidation.defaultProps = { rules: {} }

export default SliderInputWithValidation
