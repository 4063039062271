import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getKnowledges as getKnowledgesList } from '../../api/lib/knowledge'

const getKnowledges = createAsyncThunk('getKnowledges', async (_, thunkApi) => {
  const response = await getKnowledgesList()
  return response.data
})

const initialState = {}

export const knowledgeSlice = createSlice({
  name: 'knowledge',
  initialState,
  reducers: {
    setKnowledges: (state, { payload }) => {
      state.data = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getKnowledges.pending, (state, action) => {
        state.error = false
        state.loading = true
      })
      .addCase(getKnowledges.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(getKnowledges.rejected, (state, action) => {
        state.loading = false
        state.error = true
      })
  },
})

export const knowledgeSelector = (state) =>
  state.knowledge?.data || { results: [] }
export const knowledgeSliceActions = {
  ...knowledgeSlice.actions,
  getKnowledges,
}
export default knowledgeSlice.reducer
