import { useCallback, useState } from 'react'
import { debounce } from '@mui/material'
import Autocomplete from './Autocomplete'

const AutocompleteAsync = (props) => {
  const { endpoint, createEndpoint, ...others } = props
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const getData = useCallback(
    debounce((title = '') => {
      endpoint(title)
        .then((response) => {
          setLoading(false)
          const temp = response.data
          if (createEndpoint) {
            if (title.length && !temp.length) {
              temp.push({
                title,
                createdByUser: true,
                id: title,
              })
            }
          }
          setData(temp)
        })
        .catch(() => {
          setLoading(false)
        })
    }, 500),
    []
  )
  const createData = (payload, newItem) => {
    const index = payload.findIndex((item) => item.id === newItem.title)
    createEndpoint(payload[index].title).then((response) => {
      payload[index] = response.data
      setData((prev) => {
        const temp = [...prev]
        temp[temp.findIndex((i) => i.id === newItem.title)] = response.data
        return temp
      })
      others.onChange(payload)
    })
  }

  return (
    <Autocomplete
      {...others}
      options={data}
      loading={loading}
      noOptionsText={props.noOptionsText}
      onInputChange={(event, newInputValue) => {
        if (newInputValue.length === 0) return
        setLoading(true)
        getData(newInputValue)
      }}
      onChange={(newValue, reason) => {
        if (
          newValue &&
          newValue.length > 0 &&
          newValue[newValue.length - 1].createdByUser
        ) {
          createData(newValue, newValue[newValue.length - 1])
        } else {
          others.onChange(newValue)
        }
      }}
      // filterOptions={(options, params, filtered) => {
      //   if (!createEndpoint) return filtered

      //   const { inputValue } = params
      //   const isExisting = options.some((option) => inputValue === option.title)
      //   if (inputValue !== '' && !isExisting && filtered.length === 0) {
      //     filtered.push({
      //       title: inputValue,
      //       createdByUser: true,
      //       id: inputValue,
      //     })
      //   }

      //   return filtered
      // }}
    />
  )
}

AutocompleteAsync.defaultProps = {
  noOptionsText: 'Type to search',
}

export default AutocompleteAsync
