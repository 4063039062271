import { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import CssBaseline from '@mui/material/CssBaseline'
import { GoogleOAuthProvider } from '@react-oauth/google'

import store from './store/store'
import './index.css'
import RouterRenderer from './router/RouterRenderer'
import disableReactDevTools from './utils/disableDevTools'

import { TranslationWrapper } from './locale/translationContext'

const target = document.querySelector('#root')

const App = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') disableReactDevTools()
  }, [])

  return (
    <TranslationWrapper>
      <Provider store={store}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID}>
          <CssBaseline />
          <RouterRenderer />
        </GoogleOAuthProvider>
      </Provider>
    </TranslationWrapper>
  )
}

const root = createRoot(target)
root.render(<App />)
