import {
  Apartment,
  ApartmentOutlined,
  ArrowDropDown,
  GridViewOutlined,
  Logout,
  OpenInNewOutlined,
  Settings,
} from '@mui/icons-material'
import {
  Avatar,
  Divider,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import { useState } from 'react'
// import routeNames from '../../../router/routeNames'
import routeNames from '../../router/routeNames'
import { useTranslate } from '../../locale/useTranslateHook'
import { Link } from 'react-router-dom'

const UserDropDown = (props) => {
  const { user } = props
  const t = useTranslate()

  const [anchorEl, setAnchorEl] = useState(null)
  const menuOpen = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!user) return <></>

  return (
    <>
      <ListItemButton
        // component={Link}
        // to={routeNames.profile}
        onClick={handleClick}
        title={'Profile'}
        sx={{
          // padding: '8px 8px 8px 0px',
          padding: '0px',
          '&:hover': { background: 'transparent' },
          '&:focus': { background: 'transparent' },
          flexGrow: 0,
          ml: 2,
        }}
        aria-controls={menuOpen ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}>
        <ArrowDropDown sx={{ color: 'black' }} />
        {/* TODO: handle long names */}
        <Typography
          noWrap
          sx={{
            maxWidth: { xs: '80px', md: 'unset' },
            display: { xs: 'none', md: 'inline-block' },
            marginRight: 1,
            color: 'black',
          }}>
          {user.name}
        </Typography>
        <ListItemIcon sx={{ minWidth: 0 }}>
          <Avatar sx={{ backgroundColor: 'primary.main' }}>
            <ApartmentOutlined />
          </Avatar>
        </ListItemIcon>
      </ListItemButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={menuOpen}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 15,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <MenuItem disabled>
          <Typography>{user.username}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to={routeNames.logout}>
          <ListItemIcon>
            <Logout fontSize="small" color="error" />
          </ListItemIcon>
          <Typography color="error">{t('Logout')}</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export default UserDropDown
