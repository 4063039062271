import axios from 'axios'
import store from '../store/store'
import isAuthenticated, { getAccessToken } from '../shared/utils/auth'
import routeNames from '../router/routeNames'

const createInstance = () => {
  let instance = axios.create({
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    baseURL: process.env.REACT_APP_BASE_URL,
  })

  instance.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: isAuthenticated() ? getAccessToken() : '',
  }

  return instance
}

const api = createInstance()

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = routeNames.logout
    } else {
      return Promise.reject(error)
    }
  }
)

export const setToken = (token) => {
  api.defaults.headers = {
    ...api.defaults.headers,
    Authorization: `Token ${token}`,
  }
}

export const removeToken = () => {
  delete axios.defaults.headers.common['Authorization']
}

export default api
