import { Controller } from 'react-hook-form'
import Autocomplete from './Autocomplete'

const AutocompleteWithValidation = (props) => {
  const { name, control, rules, helperText, asVal, ...autocompleteProps } =
    props
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: { onChange, value, onBlur },
          fieldState: { invalid, isTouched, isDirty, error },
        }) => (
          <Autocomplete
            onChange={(objectVal) => onChange(objectVal)}
            margin="none"
            error={error}
            helperText={helperText}
            // value={!multiple ? (asVal ? value[asVal] : value) : value}
            value={value}
            onBlur={onBlur}
            required={rules?.required}
            {...autocompleteProps}
          />
        )}
      />
    </>
  )
}

export default AutocompleteWithValidation
