import { createContext, useContext, useEffect, useState } from 'react'
import { CacheProvider } from '@emotion/react'
import { ThemeProvider } from '@mui/material/styles'
import createEmotionCache from '../styles/createEmotionCache'
import theme from '../styles/theme'
import Loader from '../shared/components/global/Loader'
import { selectedLang, setSelectedLang } from '../shared/utils/auth'
import fa from './translations/fa.json'
import no from './translations/no.json'
import se from './translations/se.json'
import zh from './translations/zh.json'
import ar from './translations/ar.json'
import es from './translations/es.json'
import fr from './translations/fr.json'
import it from './translations/it.json'

export const languages = {
  ...(process.env.NEXT_PUBLIC_DISABLE_FA === 'true'
    ? {}
    : {
        fa: {
          code: 'fa',
          title: 'فارسی',
          translations: fa,
          rtl: true,
        },
      }),
  en: {
    code: 'en',
    title: 'English',
    translations: {},
  },
  no: {
    code: 'no',
    title: 'Norsk',
    translations: no,
  },
  se: {
    code: 'se',
    title: 'Svenska',
    translations: se,
  },
  zh: {
    code: 'zh',
    title: '汉语',
    translations: zh,
  },
  ar: {
    code: 'ar',
    title: 'العربیة',
    translations: ar,
    rtl: true,
  },
  es: {
    code: 'es',
    title: 'Español',
    translations: es,
  },
  fr: {
    code: 'fr',
    title: 'Français',
    translations: fr,
  },
  it: {
    code: 'it',
    title: 'Italiano',
    translations: it,
  },
}

const TranslationContext = createContext()

let clientSideEmotionCache, locale

export function TranslationWrapper({ children }) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    locale =
      languages[selectedLang()] || languages[process.env.REACT_APP_DEFAULT_LANG]
    import(`../styles/fonts/font-${locale.rtl ? 'rtl' : 'ltr'}.css`)
    document.documentElement.setAttribute('lang', locale.code)
    document.body.setAttribute('dir', locale.rtl ? 'rtl' : 'ltr')
    clientSideEmotionCache = createEmotionCache(!!locale.rtl)
    setSelectedLang(locale.code)
    setLoading(false)
  }, [])

  return !error ? (
    loading ? (
      <Loader />
    ) : (
      <CacheProvider value={clientSideEmotionCache}>
        <ThemeProvider theme={theme(!!locale.rtl)}>
          <TranslationContext.Provider value={locale.translations}>
            {children}
          </TranslationContext.Provider>
        </ThemeProvider>
      </CacheProvider>
    )
  ) : (
    <h1>ERROR</h1>
  )
}

export function useTranslations() {
  return useContext(TranslationContext)
}
