import { Fragment, useEffect, useMemo, useState } from 'react'
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Avatar,
  Button,
  MenuItem,
  Divider,
  Menu,
  Collapse,
  Container,
  useMediaQuery,
} from '@mui/material'
import {
  Menu as MenuIcon,
  ChevronLeft,
  GridViewOutlined,
  Inventory2Outlined,
  ElectricalServicesOutlined,
  PersonOutline,
  PollOutlined,
  ApartmentOutlined,
  SettingsOutlined,
  ThumbsUpDownOutlined,
  OpenInNewOutlined,
  LogoutOutlined,
  QuestionAnswerOutlined,
  DescriptionOutlined,
  Settings,
  Logout,
  Apartment,
  ArrowDropDown,
  MessageOutlined,
  MailOutlined,
  CampaignOutlined,
  ReviewsOutlined,
  ExpandLess,
  ExpandMore,
  FiberManualRecordOutlined,
  GroupWorkOutlined,
  QuestionMark,
  ForumOutlined,
  TipsAndUpdatesOutlined,
  GroupsOutlined,
  LoopOutlined,
  FolderCopyOutlined,
  HeadsetMicOutlined,
  LinearScaleOutlined,
  LibraryBooksOutlined,
  MoreVertOutlined,
  ChevronRight,
  BookOutlined,
  RouteOutlined,
} from '@mui/icons-material'
import isRTL from '../../../utils/isRTL'
import styled from '@emotion/styled'
import routeNames from '../../../router/routeNames'
import { Link, matchPath, useLocation, useParams } from 'react-router-dom'
import { colors } from '../../../styles/theme'
import isAuthenticated from '../../../shared/utils/auth'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../store/slices/userSlice'
import Image from '../../../components/global/Image'
import UserDropDown from '../../../components/global/UserDropDown'
import { breakpoints } from '../../../styles/theme'
import { useTranslate } from '../../../locale/useTranslateHook'
import Autocomplete from '../../../shared/components/global/Autocomplete'
import { knowledgeSelector } from '../../../store/slices/knowledgeSlice'
import Chat from '../../../containers/Chat'
import I18n from './I18n'

const drawerWidth = 240
const chatDrawerWidth = 350

export const CustomAppBar = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'open' &&
    prop !== 'notRender' &&
    prop !== 'mobile' &&
    prop !== 'notMobile' &&
    prop !== 'chatOpen',
})(({ theme, open, notRender, mobile, notMobile, chatOpen }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...((open || chatOpen) && {
    width: `calc(100% - ${
      notRender
        ? 0
        : mobile
        ? chatOpen
          ? 0
          : '100vw'
        : (open ? drawerWidth : 0) + (chatOpen ? chatDrawerWidth : 0) + 'px'
    })`,
  }),
  ...(open && {
    marginLeft: notRender ? 0 : mobile ? '100vw' : drawerWidth,
  }),
  ...(chatOpen && {
    marginRight: notRender ? 0 : mobile ? '100vw' : chatDrawerWidth,
  }),
}))
export const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) =>
    prop !== 'open' &&
    prop !== 'mobile' &&
    prop !== 'notMobile' &&
    prop !== 'chat',
})(({ theme, open, mobile, notMobile, chat }) => ({
  '&': {
    width: mobile ? '100vw' : chat ? chatDrawerWidth : drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      // width: theme.spacing(7),
      width: 0,
      [theme.breakpoints.up('sm')]: {
        width: chat ? 0 : theme.spacing(9),
      },
    }),
  },
  '& .MuiDrawer-paper': {
    // position: 'relative',
    position: 'fixed',
    height: '100vh',
    whiteSpace: 'nowrap',
    width: mobile ? '100vw' : chat ? chatDrawerWidth : drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      // width: theme.spacing(7),
      width: 0,
      [theme.breakpoints.up('sm')]: {
        width: chat ? 0 : theme.spacing(9),
      },
    }),
  },
  '& .MuiPaper-root': {
    // scrollbarWidth: 'none',
    // scrollbarColor: '#bababa #ffffff',
    overflowY: 'overlay',
  },
  /* Chrome, Edge, and Safari */
  '& .MuiPaper-root:hover::-webkit-scrollbar-thumb': {
    backgroundColor: '#bababa',
  },
  '& .MuiPaper-root::-webkit-scrollbar': {
    width: '7px',
  },
  '& .MuiPaper-root::-webkit-scrollbar-track': {
    background: '#ffffff',
  },
  '& .MuiPaper-root::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent',
    borderRadius: '10px',
    position: 'absolute',
  },
}))

const CustomNavbarLink = (page) => {
  const location = useLocation()
  const items = page.to ? [page] : page.children ? page.children : []
  const match = items.some((item) => location.pathname === item.to)
  const [open, setOpen] = useState(false)

  if (page.inline)
    return (
      <Button
        startIcon={page.icon}
        sx={{ mr: 1 }}
        component={Link}
        to={page.to}>
        {page.title}
      </Button>
    )

  if (page.children)
    return (
      <>
        <ListItemButton
          onClick={() => setOpen(!open)}
          selected={Boolean(match)}
          title={page.title}
          sx={{ mb: 1, padding: { xs: '8px', md: '8px 16px 8px 16px' } }}>
          <ListItemIcon>{page.icon}</ListItemIcon>
          <ListItemText primary={page.title} sx={page.titleStyle} />
          {open ? (
            <ExpandLess color="primary" />
          ) : (
            <ExpandMore color="primary" />
          )}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {page.children.map((item) => (
              <Box key={item.title}>{CustomNavbarLink(item)}</Box>
            ))}
          </List>
        </Collapse>
      </>
    )

  return (
    <ListItemButton
      component={page.href ? 'a' : Link}
      to={page.to}
      href={page.href}
      target={page.href ? '_blank' : '_self'}
      selected={Boolean(match)}
      title={page.title}
      sx={{ mb: 1, padding: { xs: '8px', md: '8px 16px 8px 16px' } }}>
      <ListItemIcon>{page.icon}</ListItemIcon>
      <ListItemText primary={page.title} sx={page.titleStyle} />
    </ListItemButton>
  )
}

const ChatSidebar = (props) => {
  const { chatOpen, toggleChatDrawer } = props

  const t = useTranslate()
  const mobile = useMediaQuery(`(max-width: ${breakpoints.sm}px)`)
  const notMobile = useMediaQuery(`(min-width: ${breakpoints.sm}px)`)

  const knowledges = useSelector(knowledgeSelector)
  const [knowledge, setKnowledge] = useState(knowledges.results[0])

  const isKnowledgeDetailPage = matchPath(
    routeNames.knowledgeDetail.pattern,
    location.pathname
  )
  useEffect(() => {
    if (isKnowledgeDetailPage) {
      const temp = knowledges.results.find(
        (item) => item.id == isKnowledgeDetailPage.params.id
      )
      if (temp) setKnowledge(temp)
    }
  }, [location.pathname])

  return (
    <CustomDrawer
      variant="permanent"
      open={chatOpen}
      chat
      anchor="right"
      mobile={mobile}
      notMobile={notMobile}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row-reverse',
          px: [1],
        }}>
        <Typography variant="body1" fontWeight="bold" flexGrow={1} pl={2}>
          {t('Try chat based on knowledge')}
        </Typography>
        <IconButton variant="square" onClick={toggleChatDrawer}>
          {isRTL() ? (
            <ChevronLeft color="primary" />
          ) : (
            <ChevronRight color="primary" />
          )}
        </IconButton>
      </Toolbar>
      <Box sx={{ p: 1 }}>
        <Autocomplete
          onChange={(objectVal) => setKnowledge(objectVal)}
          margin="none"
          value={knowledge}
          label={t('Knowledges')}
          options={knowledges.results}
        />
      </Box>
      <Chat
        knowledge={knowledge}
        containerProps={{
          sx: { p: '8px !important', height: 'calc(100vh - 136px)' },
          maxWidth: 'sm',
        }}
        admin
      />
    </CustomDrawer>
  )
}

const Navbar = ({
  isLoadingUserData,
  onUpdate,
  navbarRenderStatus,
  fluidContainer,
  children,
}) => {
  const t = useTranslate()
  const userState = useSelector(userSelector)
  const mobile = useMediaQuery(`(max-width: ${breakpoints.sm}px)`)
  const tablet = useMediaQuery(`(max-width: ${breakpoints.lg}px)`)
  const notMobile = useMediaQuery(`(min-width: ${breakpoints.sm}px)`)

  const [navbarStatus, setOpen] = useState(true)
  const open = useMemo(
    () => (mobile ? navbarStatus : false),
    [navbarStatus, mobile]
  )
  const [chatOpen, setChatOpen] = useState(true)
  const toggleDrawer = () => {
    setOpen(!open)
    onUpdate([!open, !chatOpen])
  }
  const toggleChatDrawer = () => {
    setChatOpen(!chatOpen)
    onUpdate([!open, !chatOpen])
  }
  useEffect(() => {
    if (mobile && open) toggleDrawer()
    else if (notMobile && !open) toggleDrawer()
  }, [mobile])
  useEffect(() => {
    if (tablet && chatOpen) toggleChatDrawer()
  }, [tablet])

  const handleScroll = (id) => {
    document.querySelector(id).scrollIntoView({
      behavior: 'smooth',
    })
  }

  const usageData = useMemo(() => {
    return userState.data?.businessObject?.usage_data || []
  }, [JSON.stringify(userState.data?.businessObject?.usage)])

  const pages = useMemo(() => {
    const temp = [
      // {
      //   title: t('Home'),
      //   icon: <GridViewOutlined color="primary" />,
      //   to: routeNames.homepage,
      // },
      {
        title: t('Knowledges'),
        icon: <BookOutlined color="primary" />,
        to: routeNames.knowledgesList,
      },
      {
        title: t('Agents'),
        icon: <RouteOutlined color="primary" />,
        to: routeNames.agentsList,
      },
    ]
    return temp
  }, [usageData])

  const location = useLocation()
  const notRender =
    location.pathname.includes(routeNames.login) ||
    location.pathname.includes(routeNames.logout) ||
    location.pathname.includes(routeNames.register) ||
    !isAuthenticated() || //TODO
    isLoadingUserData

  useEffect(() => {
    if (mobile && open) toggleDrawer()
    if (mobile && chatOpen) toggleChatDrawer()
  }, [location])
  useEffect(() => {
    navbarRenderStatus(!notRender)
  }, [notRender])

  const [anchorEl, setAnchorEl] = useState(null)
  const menuOpen = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  if (notRender)
    return (
      <>
        <AppBar
          sx={{
            backdropFilter: 'blur(8px)',
            boxShadow: 'none',
            backgroundColor: 'rgba(255,255,255,0.62)',
            borderBottom: '1px solid #DADADA',
            top: 0,
          }}
          // color="primaryDark"
          position="sticky">
          <Container
            maxWidth={fluidContainer ? false : 'lg'}
            sx={{ paddingTop: '0 !important', paddingBottom: '0 !important' }}>
            <Toolbar
              disableGutters
              sx={{ alignItems: 'stretch', justifyContent: 'space-between' }}>
              <Typography
                noWrap
                component={Link}
                to="/"
                sx={(theme) => ({
                  fontWeight: '500',
                  px: 2,
                  flexGrow: 1,
                  lineHeight: '56px',
                  textDecoration: 'none',
                  [theme.breakpoints.up('md')]: {
                    flexGrow: 0,
                    // borderLeft: '1px solid #DADADA',
                    // borderRight: '1px solid #DADADA',
                    px: '32px',
                    // pt: '42px',
                    // pb: '37px',
                    lineHeight: '64px',
                    fontSize: '24px',
                  },
                })}
                color={'primary'}>
                {t('Rostam')}
              </Typography>

              {isAuthenticated() && (
                <>
                  {!mobile && (
                    <Button
                      LinkComponent={Link}
                      to={routeNames.logout}
                      color="error"
                      sx={{ borderRadius: 0 }}>
                      {t('Logout')}
                    </Button>
                  )}
                  {mobile && (
                    <>
                      <IconButton
                        onClick={handleClick}
                        aria-controls={
                          menuOpen ? 'account-menu-wizard' : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={menuOpen ? 'true' : undefined}>
                        <MoreVertOutlined />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        id="account-menu-wizard"
                        open={menuOpen}
                        onClose={handleClose}
                        onClick={handleClose}
                        transformOrigin={{
                          horizontal: 'right',
                          vertical: 'top',
                        }}
                        anchorOrigin={{
                          horizontal: 'right',
                          vertical: 'bottom',
                        }}>
                        <MenuItem component={Link} to={routeNames.logout}>
                          <ListItemIcon>
                            <Logout fontSize="small" color="error" />
                          </ListItemIcon>
                          <Typography color="error">{t('Logout')}</Typography>
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </>
              )}
            </Toolbar>
          </Container>
        </AppBar>
        {children}
      </>
    )

  return (
    <>
      {!notRender && (
        <ChatSidebar chatOpen={chatOpen} toggleChatDrawer={toggleChatDrawer} />
      )}
      <Box
        sx={(theme) => ({
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          ...((open || chatOpen) && {
            width: `calc(100% - ${
              (open ? drawerWidth : 0) + (chatOpen ? chatDrawerWidth : 0) + 'px'
            })`,
          }),
          margin: 'auto',
          flexGrow: 1,
          // flexDirection: 'row-reverse',
          // ...(open && {
          //   marginLeft: notRender ? 0 : mobile ? '100vw' : drawerWidth,
          // }),
          // ...(chatOpen && {
          //   marginRight: notRender ? 0 : mobile ? '100vw' : chatDrawerWidth,
          // }),
        })}>
        <CustomAppBar
          sx={{
            backdropFilter: 'blur(8px)',
            boxShadow: 'inset 0px -1px 1px #eaeef3',
            backgroundColor: 'rgba(255,255,255,0.72)',
          }}
          color="inherit"
          // position="fixed"
          open={open}
          chatOpen={chatOpen}
          notRender={notRender}
          mobile={mobile}
          notMobile={notMobile}>
          {!notRender && (
            <>
              <Toolbar
                sx={{
                  pr: '24px', // keep right padding when drawer closed
                  display: open && mobile ? 'none' : 'flex',
                }}>
                {mobile && (
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    variant="square"
                    sx={{
                      marginRight: { xs: '0px', md: '36px' },
                      marginLeft: '-16px',
                      padding: '8px 16px 8px 16px',
                      ...(open && { display: 'none' }),
                    }}>
                    <MenuIcon color="primary" />
                  </IconButton>
                )}
                {/* <Box sx={{ flexGrow: 1 }}></Box> */}
                {isAuthenticated() && (
                  <>
                    {!mobile && (
                      <>
                        <Typography
                          component={Link}
                          variant="h5"
                          color="inherit"
                          noWrap
                          to="/"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            px: 2,
                            textDecoration: 'none',
                            mr: 3,
                          }}
                          fontWeight="bold">
                          {t('Rostam')}
                        </Typography>
                        {pages.map((page, index) =>
                          page.render === undefined || page.render === true ? (
                            <CustomNavbarLink
                              {...page}
                              key={page.title}
                              inline
                            />
                          ) : null
                        )}
                      </>
                    )}
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <I18n />
                    <UserDropDown user={userState.data} />
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="open chat drawer"
                      onClick={toggleChatDrawer}
                      variant="square"
                      sx={{
                        marginLeft: '10px',
                        marginRight: '-16px',
                        padding: '8px 16px 8px 16px',
                        ...(chatOpen && { display: 'none' }),
                      }}>
                      <MessageOutlined color="primary" />
                    </IconButton>
                  </>
                )}
              </Toolbar>
            </>
          )}
        </CustomAppBar>
        {children}
      </Box>
      {!notRender && mobile && (
        <>
          <CustomDrawer
            variant="permanent"
            open={open}
            mobile={mobile}
            notMobile={notMobile}>
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: [1],
              }}>
              <Typography
                component={Link}
                variant="h5"
                color="inherit"
                noWrap
                to="/"
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  alignItems: 'center',
                  px: 2,
                  textDecoration: 'none',
                }}
                fontWeight="bold">
                {t('Rostam')}
              </Typography>

              <IconButton variant="square" onClick={toggleDrawer}>
                {isRTL() ? (
                  <ChevronRight color="primary" />
                ) : (
                  <ChevronLeft color="primary" />
                )}
              </IconButton>
            </Toolbar>

            <List sx={{ px: 1 }}>
              {pages.map((page, index) =>
                page.render === undefined || page.render === true ? (
                  <CustomNavbarLink {...page} key={page.title} />
                ) : null
              )}
            </List>
          </CustomDrawer>
        </>
      )}
    </>
  )
}

export default Navbar
