import { lazy } from 'react'
import routeNames from './routeNames'

const KnowledgeDetailComponent = lazy(() =>
  import('../containers/knowledges/Detail')
)

const routes = [
  // {
  //   path: routeNames.landing,
  //   Main: lazy(() => import('../containers/Landing')),
  //   authRequired: false,
  // },
  // {
  //   path: routeNames.edadLanding,
  //   Main: lazy(() => import('../containers/EdadLanding')),
  //   authRequired: false,
  // },

  {
    path: routeNames.login,
    Main: lazy(() => import('../containers/Login')),
    authRequired: false,
    notAuthenticated: true,
  },
  // {
  //   path: routeNames.register,
  //   Main: lazy(() => import('../containers/Register')),
  //   routeName: 'registerPage',
  //   title: 'Register Page',
  //   authRequired: false,
  //   notAuthenticated: true,
  // },
  {
    path: routeNames.logout,
    Main: lazy(() => import('../containers/Logout')),
    authRequired: true,
  },

  // {
  //   path: routeNames.homepage,
  //   Main: lazy(() => import('../containers/Home')),
  //   authRequired: true,
  // },
  {
    path: routeNames.knowledgesList,
    Main: lazy(() => import('../containers/knowledges/List')),
    authRequired: true,
  },
  {
    path: routeNames.knowledgeDetail.pattern,
    Main: KnowledgeDetailComponent,
    authRequired: true,
  },
  {
    path: routeNames.contentsList.pattern,
    Main: KnowledgeDetailComponent,
    authRequired: true,
  },
  {
    path: routeNames.exportQuestions.pattern,
    Main: KnowledgeDetailComponent,
    authRequired: true,
  },
  {
    path: routeNames.askedQuestions.pattern,
    Main: KnowledgeDetailComponent,
    authRequired: true,
  },
  {
    path: routeNames.usersComments.pattern,
    Main: KnowledgeDetailComponent,
    authRequired: true,
  },
  {
    path: routeNames.settings.pattern,
    Main: KnowledgeDetailComponent,
    authRequired: true,
  },
  {
    path: routeNames.contentDetail.pattern,
    Main: lazy(() => import('../containers/contents/Detail')),
    authRequired: true,
  },

  {
    path: routeNames.chat.pattern,
    redirect: routeNames.talk.pattern,
    routeName: 'chat',
    title: 'Chat',
    authRequired: false,
  },
  {
    path: routeNames.talk.pattern,
    Main: lazy(() => import('../containers/Chat')),
    routeName: 'talk',
    title: 'Talk',
    authRequired: false,
  },
  {
    path: routeNames.talkDetail.pattern,
    Main: lazy(() => import('../containers/Chat')),
    routeName: 'talkDetail',
    title: 'Talk detail',
    authRequired: false,
  },

  {
    path: routeNames.agentsList,
    Main: lazy(() => import('../containers/agents/List')),
    authRequired: true,
  },
  // {
  //   path: routeNames.agentDetail.pattern,
  //   Main: lazy(() => import('../containers/agents/Detail')),
  //   authRequired: true,
  // },
  {
    path: routeNames.agent.pattern,
    Main: lazy(() => import('../containers/agents/Layout')),
    authRequired: true,
    children: [
      {
        path: routeNames.agent.details.pattern,
        Main: lazy(() => import('../containers/agents/Detail')),
      },
      {
        path: routeNames.agent.intents.pattern,
        Main: lazy(() => import('../containers/agents/Intents')),
      },
      {
        path: routeNames.agent.intentDetail.pattern,
        Main: lazy(() => import('../containers/agents/IntentDetail')),
      },
      {
        path: routeNames.agent.entityTypes.pattern,
        Main: lazy(() => import('../containers/agents/EntityTypes')),
      },
      {
        path: routeNames.agent.entityTypeDetail.pattern,
        Main: lazy(() => import('../containers/agents/EntityTypeDetail')),
      },
      {
        path: routeNames.agent.webhooks.pattern,
        Main: lazy(() => import('../containers/agents/Webhooks')),
      },
      {
        path: routeNames.agent.webhookDetail.pattern,
        Main: lazy(() => import('../containers/agents/WebhookDetail')),
      },
    ],
  },

  {
    path: '*',
    // Main: lazy(() => import('../containers/NotFound')),
    redirect: routeNames.knowledgesList,
    routeName: 'notFoundPage',
    title: '404 Page',
    authRequired: false,
  },
]

export default routes
