import { Replay } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { useTranslate } from '../../locales/useTranslateHook'
import Loader from './Loader'

const LoadOrError = (props) => {
  const translate = useTranslate()
  return (
    <Box
      sx={{ width: '100%' }}
      mt={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center">
      {props.loading && <Loader local />}
      {props.error && (
        <>
          <Typography sx={{ mb: 1 }} fontWeight="bold">
            {props.error}
          </Typography>
          <Button onClick={props.retry} startIcon={<Replay />}>
            {/* {translate('Try again.')} */}
            {/* TODO: handle translation on shared components */}
            تلاش مجدد
          </Button>
        </>
      )}
    </Box>
  )
}

export default LoadOrError
