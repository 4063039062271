import { Controller } from 'react-hook-form'
import RadioGroup from './RadioGroup.js'

const RadioGroupWithValidation = (props) => {
  const { name, control, rules, helperText, ...other } = props

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field,
        fieldState: { invalid, isTouched, isDirty, error },
      }) => (
        <RadioGroup
          {...field}
          error={error}
          name={name}
          rules={rules}
          helperText={helperText}
          {...other}
        />
      )}
    />
  )
}

RadioGroupWithValidation.defaultProps = {
  helperText: {},
  rules: {},
}

export default RadioGroupWithValidation
