import { Controller } from 'react-hook-form'
import Datepicker from './Datepicker'

const DatepickerWithValidation = (props) => {
  const { name, control, rules, helperText, ...textFieldProps } = props

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field,
        fieldState: { invalid, isTouched, isDirty, error },
      }) => (
        <Datepicker
          {...field}
          error={error}
          name={name}
          rules={rules}
          helperText={helperText}
          {...textFieldProps}
        />
      )}
    />
  )
}

DatepickerWithValidation.defaultProps = {}

export default DatepickerWithValidation
