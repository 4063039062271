import axios from '../axios'

const base = '/knowledge'

export const getKnowledges = (filter = '') => {
  return axios.get(`${base}/${filter}`)
}

export const getKnowledgeDetail = (slug) => {
  return axios.get(`${base}/${slug}/detail/`)
}
export const getKnowledge = (id) => {
  return axios.get(`${base}/${id}/`)
}

export const askQuestion = (slug, payload) => {
  return axios.post(`${base}/${slug}/qa/`, payload)
}

export const initConversation = (slug, payload) => {
  return axios.post(`${base}/${slug}/init-talk/`, payload)
}

export const getConversation = (id) => {
  return axios.get(`${base}/talk/${id}/`)
}

export const askQuestionInConversation = (id, payload) => {
  return axios.post(`${base}/talk/${id}/qa/`, payload)
}

export const createKnowledge = (payload) => {
  return axios.post(`${base}/create/`, payload, {
    mimeType: 'multipart/form-data',
  })
}
export const editKnowledge = (id, payload) => {
  return axios.put(`${base}/${id}/`, payload, {
    mimeType: 'multipart/form-data',
  })
}

export const submitReview = (id, payload) => {
  return axios.post(`${base}/asked-question/${id}/submit-review/`, payload)
}
export const submitAnswerRequest = (id, payload) => {
  return axios.post(`${base}/asked-question/${id}/request-for-answer/`, payload)
}

export const getContents = (id) => {
  return axios.get(`${base}/${id}/content/`)
}
export const getContentDetail = (id, contentId) => {
  return axios.get(`${base}/${id}/content/${contentId}/`)
}

export const createContent = (id, payload) => {
  return axios.post(`${base}/${id}/content/`, payload, {
    mimeType: 'multipart/form-data',
  })
}
export const editContent = (id, contentId, payload) => {
  return axios.put(`${base}/${id}/content/${contentId}/`, payload, {
    mimeType: 'multipart/form-data',
  })
}
export const editContentGeneratedText = (id, partId, payload) => {
  return axios.put(`${base}/${id}/content/part/${partId}/`, payload)
}

export const getAskedQuestions = (id) => {
  return axios.get(`${base}/${id}/asked-questions/`)
}

export const updateVDB = (id, payload) => {
  return axios.post(`${base}/${id}/update-vdb/`, payload)
}
export const hardUpdateVDB = (id, payload) => {
  return axios.post(`${base}/${id}/hard-update-vdb/`, payload)
}
