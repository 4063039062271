import {
  // Button,
  // ButtonGroup,
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup,
} from '@mui/material'

const ToggleButton = (props) => {
  const { value, onChange, items, asVal } = props

  const handleChange = (payload) => {
    onChange(items.find((i) => i[asVal] === payload))
  }

  return (
    <>
      {/* <ButtonGroup variant="outlined" sx={{ height: '50px', width: '100%' }}>
        {items.map((item) => (
          <Button
            sx={[
              value[asVal] === item[asVal]
                ? {
                    backgroundColor: 'primary.main',
                    color: 'white',
                    '&:hover': { color: 'primary.main' },
                  }
                : {},
              { width: '100%' },
            ]}
            onClick={() => handleChange(item)}
            key={item.id}>
            {item.title}
          </Button>
        ))}
      </ButtonGroup> */}
      <ToggleButtonGroup
        value={value[asVal]}
        exclusive
        onChange={(e, v) => handleChange(v)}
        sx={{ width: '100%' }}
        color="primary">
        {items.map((item) => (
          <MuiToggleButton
            value={item[asVal]}
            key={item.id}
            sx={{ flexGrow: 1 }}>
            {item.title}
          </MuiToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  )
}

ToggleButton.defaultProps = {
  items: [],
  asVal: 'id',
}

export default ToggleButton
